import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import Loader from "../resuableComponent/loader";
import {
  fetchCVEDetails,
  fetchCVEDetailsGraph,
  fetchCVEDetailsTotal,
  fetchCVEDetailsAction,
} from "../../actions/vulnerability";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KnownsubscribersChart from "../common/apexChart/knownsubscribersChart";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData
} from "../../actions/sub-domain";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { API_URL } from "../../utils/constant";


const KnownVulnerability = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const myRef = useRef(null);
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [search, setsearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [containerClass, setContainerClass] = useState("data-container");
  const [addedRemediations, setAddedRemediations] = useState([]);
  const [ipFilter, setIpFilter] = useState("");
  const [checkPath, setCheckPath] = useState('');
  const auth = useSelector((state) => state?.auth?.user);
  const cartDispatch = useDispatch();
  const modeValue = new URLSearchParams(location?.search).get('mode');
  let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then(() => {
              fetchCVEDetails(id, dispatch, page, PageSize, search, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName": 'delta_cve_details',
      "organisationId": id,
      "recordId": row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then(() => {
              fetchCVEDetails(id, dispatch, page, PageSize, search, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  let table = useSelector(
    (state) => state.vulnerability?.cveDetails?.data?.data
  );

  let totalCount = useSelector((state) => state.vulnerability?.cveTotal?.data);

  const pagination = useSelector(
    (state) => state.vulnerability?.cveDetails?.data
  );

  let scanCompleted = useSelector(
    (state) => state.vulnerability?.scanCompleted
  );
  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  const scanDetails = useSelector(
    (state) => state?.vulnerability?.cveGraph?.data
  );

  const iPDetails = useSelector(
    (state) => state?.vulnerability?.cveDetails?.scanDetailArray?.ipDetails
  );

  useEffect(() => {
    if (table) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [table]);

  useEffect(() => {
    fetchCVEDetails(id, dispatch, page, PageSize, search, category, ipFilter);
  }, [id, dispatch, page, PageSize, search, category, ipFilter]);

  useEffect(() => {
    setTotalItems(pagination?.cveDetailsRecord);
  }, [pagination?.cveDetailsRecord]);

  useEffect(() => {
    fetchCVEDetailsTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchCVEDetailsGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setCheckPath(location.search)
  }, [location.search])

  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  console.log(addedRemediations, 'addedRemediations')
  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        pvaIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchCVEDetailsAction(
        id,
        dispatch,
        page,
        PageSize,
        search,
        category,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      pvaIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      pvaIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchCVEDetailsAction(id, dispatch, page, PageSize, search, category, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails?.[details]?.length || 0);
  }

  let mapData = table;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/cve-details/${id}/excel`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "IP Vulnerability Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const severity = (val) => {
    if (val < 4) {
      return "Low";
    }
    if (val >= 4 && val < 7) {
      return "Medium";
    }
    if (val >= 7 && val < 9) {
      return "High";
    }
    if (val >= 9) {
      return "Critical";
    }
  };

  const severityBatch = (val) => {
    if (val < 4) {
      return "badge badge-low";
    }
    if (val >= 4 && val < 7) {
      return "badge badge-med";
    }
    if (val >= 7 && val < 9) {
      return "badge badge-high";
    }
    if (val >= 9) {
      return "badge badge-critical";
    }
  };

  return (
    <div>
      {/* <Header type="security" />
      <SideNav type="security" /> */}

      {table ? (
        <div className={containerClass}>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {scanDetails && (
                    <KnownsubscribersChart
                      dates={scanDetails?.dates}
                      data={scanDetails?.scanDetails}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      setPage(1);
                      setsearch("all");
                      setfilterData("all");
                      setSelectedIds([]);
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      setPage(1);
                      setsearch("all");
                      setfilterData("all");
                      setSelectedIds([]);
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      setPage(1);
                      setfilterData("all");
                      setsearch("all");
                      setSelectedIds([]);
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      setPage(1);
                      setfilterData("all");
                      setsearch("all");
                      setSelectedIds([]);
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    value={ipFilter}
                    onChange={(e) => setIpFilter(e.target.value)}
                    placeholder="Ip Address"
                  />
                </div>
                <div className="col-sm-3">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={filterData}
                    onChange={(e) => {
                      setsearch(e.target.value);
                      setfilterData(e.target.value);
                      setPage(1);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="critical">Critical</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                </div>
                {modeValue == "report-mode" && <>
                  <div className="col-sm-1 d-flex justify-content-end">
                    {currentTableData?.length === 0 ? null : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile();
                        }}
                        style={{ float: "right" }}
                        href="/add-report"
                        className="btn btn-primary mr-1"
                      >
                        <i
                          className="fa fa-file-excel-o"
                          style={{ color: "black" }}
                        ></i>
                      </a>
                    )}
                  </div>
                </>}
              </div>
              <div class="row_boxes row_boxes_table">
                <div class="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>IP Address</th>
                        <th>CVE Name</th>
                        <th>Severity</th>
                        <th>Ageing</th>
                        <th>Description</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row) => {
                        return (
                          <tr>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(
                                      row?._id
                                    )}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td style={{ whiteSpace: "unset" }}>{row?.ip}</td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.cveId}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span
                                className={
                                  row?.severity === "medium"
                                    ? "badge badge-med"
                                    : row?.severity === "low"
                                      ? "badge badge-low"
                                      : row?.severity === "high"
                                        ? "badge badge-high"
                                        : row?.severity === "critical"
                                          ? "badge badge-critical"
                                          : "N/A"
                                }
                              >
                                {row?.severity}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {moment().diff(
                                moment(row?.cvePublishedAt),
                                "days"
                              )}{" "}
                              days
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {row?.description}
                            </td>
                            {row?.remediationPending == true ? <td>Retest in progress</td> : <>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(
                                            row?._id,
                                            event.target.value
                                          );
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                            </>
                            }
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {clientType == "Admin" ?
                              <td>
                                {row?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...row,
                                      remediationType: "Sub Domain",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(row?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={10}
                    onPageChange={(page) => {
                      setPage(page);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
          className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-6 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginRight: "28px" }}
                      >
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          onClick={submit}
                          className="btn btn-round btn-primary mr-1 pull-right"
                        >
                          Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn btn-round btn-default pull-right"
                          style={{ marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
    
  );
};

export default KnownVulnerability;
