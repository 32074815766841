import axios from "axios";
import { API_URL} from "../utils/constant";
import { OVERVIEW_LIST } from ".";

export const getOverviewList = (orgId, dispatch, page) => {
  if (orgId) {
    axios
      .get(`${API_URL}/ticket-system/${orgId}/overview?page=${page}&limit=10`)
      .then((res) => {
        dispatch({
          type: OVERVIEW_LIST,
          data: res.data,
        });
      });
  }
};
