import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import MobileApp from "../SecurityRisk/MobileApp";
import LookAlikeDomains from "../SecurityRisk/LookAlikeDomain";
import SocialMediaProfile from "../SecurityRisk/SocialMediaProfile";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion.dev";
import BrandMention from "../SecurityRisk/brandMention";

const Brand = ({ match }) => {
  return (
    
      <AnimatePresence>
        <Switch>
          <Route path={`${match.path}/mobile-apps`} component={MobileApp} />
          <Route path={`${match.path}/look-alike`} component={LookAlikeDomains} />
          <Route path={`${match.path}/social-media`} component={SocialMediaProfile} />
          <Route path={`${match.path}/brand-mention`} component={BrandMention} />
          <Route path={`${match.path}`} component={MobileApp} />
        </Switch>
      </AnimatePresence>
    
  );
};

export default Brand;
