import axios from "axios";
import { API_URL } from "../utils/constant";
import { 
    SUBDOMAIN_FETCHED,
    SUBDOMAIN_FETCHED_GRAPH,
    SUBDOMAIN_FETCHED_TOTAL,
    SUBDOMAIN_FETCHED_EXP,
    FETCH_SUBDOMAIN_DETAIL, 
    SUBDOMAIN_SCAN_STARTED,
    SUBDOMAIN_FETCHED_SSL_GRAPH, 
    SUBDOMAIN_FETCHED_SSL_TOTAL, 
    LOOK_ALIKE_FETCHED, 
    INVENTORY_DETAILS_FETCHEDV1, 
    FETCH_DNS_DETAILS, 
    FETCH_SUCCESS_DNS_DETAILS, 
    SECURITY_HEADER, 
    REMEDIATION_DATA_ADDED, 
    REMEDIATION_DATA_REMOVED ,
    REMEDIATION_DATA_GET,
    FETCH_SUCCESS_DNS_DETAILS_GRAPH, 
    FETCH_SUCCESS_DNS_DETAILS_TOTAL,
    REMEDIATION_DATA_TOTAL,
    GET_PROCESS_LIST,
    GET_PROCESS_CONTAINERS,
    GET_PROCESS_CONTAINERS_BODY_LIST
  } from "./index";
import { toastr } from "react-redux-toastr";
import $ from "jquery";

// import { toastr } from 'react-redux-toastr';

export const addRemediationData = (data, dispatch) => {
  return new Promise((resolve,reject)=>{
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (data === undefined) {
    reject("Invalid ID");
    return null;
  } else {
    axios
      .patch(`${API_URL}/ticket-system/`,data)
      .then((response) => {
        dispatch({
          type: REMEDIATION_DATA_ADDED,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
          getRemediationData(dispatch, data?.organisationId, 1)
          resolve();
        } else {
          toastr.success("Error", response.data.userMessage);
          reject(response.data.userMessage);
        }
      })
      .catch((error) => {
        reject(error);
      });
  }
});
}


export const removeRemediationData = (id, dispatch, orgId) => {
  return new Promise((resolve, reject) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    
    if (id === undefined) {
      reject("Invalid ID");
    } else {
      axios.delete(`${API_URL}/ticket-system/${id}/delete`)
        .then((response) => {
          dispatch({
            type: REMEDIATION_DATA_REMOVED,
            payload: response.data,
          });
          if (response.data.status === 200) {
            toastr.success("Success", response.data.userMessage);
            getRemediationData(dispatch, orgId, 1);
            resolve();
          } else {
            toastr.error("Error", response.data.userMessage);
            reject(response.data.userMessage);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export const getRemediationData = (dispatch, id, page) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/ticket-system/${id}/list?page=${page}&limit=10`)
      .then((response) => {
        dispatch({
          type: REMEDIATION_DATA_GET,
          payload: response.data,
        });
      })
  }
}

export const getProcessList = (dispatch, id) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  
  if (id === undefined) {
    return Promise.resolve(null);
  } else {
    return axios.post(`${API_URL}/ticket-system/${id}/process-tickets`)
      .then((response) => {
        dispatch({
          type: GET_PROCESS_LIST,
          payload: response.data,
        });
        if (response.data.hasNullTaskTypeRecords) {
          $.confirm({
            title: "Error Message",
            content: response.data.errorMsg,
            type: "red",
            buttons: {
              ok: {
                text: "ok",
                btnClass: "btn-danger",
                keys: ["enter"],
                action: function () {
                },
              },
            },
          });
        }else{
          $.confirm({
            title: "Success Message",
            content: 'Procesed successfully',
            type: "Success",
            buttons: {
              ok: {
                text: "ok",
                btnClass: "btn-success",
                keys: ["enter"],
                action: function () {
                },
              },
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching process list:", error);
      });
  }
};

export const getContainersList = (dispatch, id) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/ticket-system/${id}/containers`)
      .then((response) => {
        dispatch({
          type: GET_PROCESS_CONTAINERS,
          payload: response.data,
        });
      })
  }
}

export const getContainersBodyList = (dispatch, id,cntID) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/ticket-system/${id}/containers/${cntID}`)
      .then((response) => {
        dispatch({
          type: GET_PROCESS_CONTAINERS_BODY_LIST,
          payload: response.data,
        });
      })
  }
}


export const getRemediationActionType = (dispatch, id, data,orgId) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .put(`${API_URL}/ticket-system/${id}/actiontype`,data)
      .then((response) => {
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
          getRemediationData(dispatch,orgId,1);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
}

export const getRemediationActionNote = (dispatch, id, data,orgId) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .put(`${API_URL}/ticket-system/${id}/update-note`,data)
      .then((response) => {
          toastr.success("Success", response.data.message);
          getRemediationData(dispatch,orgId,1);
      })
  }
}

export const getRemediationActionRating = (dispatch, id, data,orgId) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .put(`${API_URL}/ticket-system/${id}/update-rating`,data)
      .then((response) => {
          toastr.success("Success", response.data.message);
          getRemediationData(dispatch,orgId,1);
      })
  }
}

export const remediationDataReset = (dispatch, id) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .put(`${API_URL}/ticket-system/reset`,id)
      .then((response) => {
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
          remediationDataTotal(dispatch,id.organisationId)
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
}

export const remediationDataTotal = (dispatch, id) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/ticket-system/${id}/getTotalActionTypeCounts`)
      .then((response) => {
        dispatch({
          type: REMEDIATION_DATA_TOTAL,
          payload: response.data,
        });
      })
  }
}

export const getRemediationAction = (dispatch, id, data, page, category) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (id === undefined) {
    return null;
  } else {
    axios
      .post(`${API_URL}/ticket-system/${id}/action?page=${page}&category=${category}`,data)
      .then((response) => {
        dispatch({
          type: REMEDIATION_DATA_GET,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
}

export const fetchWebTech = (props, page, dispatch , category, filterData, searchBy , searchValue,minExploitabilityScore,severity) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/webtech_analysis/${props}/delta?pageno=${page}&category=${category}&searchBy=${searchBy}&q=${searchValue}&type=${filterData}&minExploitabilityScore=${minExploitabilityScore}&severity=${severity}`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchWebTechAction = (props, page, dispatch , category,data) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/webtech_analysis/${props}/action?pageno=${page}&category=${category}`,data)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
  }
};

export const fetchWebTechGraph = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/webtech_analysis/${props}/graph`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchWebTotal = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/webtech_analysis/${props}/totals`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};


export const fetchSubDomains = (props, page, dispatch ,category,domain_search_query,status) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .get(`${API_URL}/expired-ssl-certificates/${props}/delta?page=${page}&category=${category}&domain_search_query=${domain_search_query}&status=${status}`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_EXP,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_EXP,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchSubDomainsAction = (props, dispatch ,page , category ,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .post(`${API_URL}/expired-ssl-certificates/${props}/action?page=${page}&category=${category}`,data)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_EXP,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_EXP,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchSubDomainsTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/expired-ssl-certificates/${props}/totals`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchSubDomainsGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/expired-ssl-certificates/${props}/graph`)
      .then((response) => {
        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SUBDOMAIN_FETCHED_SSL_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const addSubDmoan = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .post(`${API_URL}/createSubDomain`, { ...props })
      .then((response) => {
        if(response.data.error){
          toastr.error("Error", response.data.userMessage);
          return false;
        }else{
          toastr.success("Success", response.data.userMessage);
        }
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        toastr.error("Error", message);
        dispatch({
          type: SUBDOMAIN_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const startScanSubdomain = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .post(`${API_URL}/start-scan/${props.subdomain}`)
      .then((response) => {
        if(response.data.error){
          toastr.error("Error", response.data.userMessage);
        }else{
          toastr.success("Success", response.data.userMessage);
        }
        dispatch({
          type: SUBDOMAIN_SCAN_STARTED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        toastr.error("Error", message);
        dispatch({
          type: SUBDOMAIN_SCAN_STARTED,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDetails = (props, dispatch) => {
  //console.log(props);
  if (props !== undefined) {
    axios.get(`${API_URL}/dns-details/${props}`).then((res) => {
      if (res.data.error) {
        toastr.error("ERROR", "Something went wrong");
      } else {
        toastr.success("SUCCESS", "Data Fetched.");
      }
      dispatch({
        type: FETCH_SUBDOMAIN_DETAIL,
        subDomainDetail: res.data,
      });
    });
  }
};

export const subDomainScan = (props, dispatch) => {
  //console.log(props);
  if (props !== undefined) {
    // const cookie = new Cookies();
    // const session = cookie.get("session");
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    axios.post(`${API_URL}/start-scan`, props ).then((res) => {
      if (res.data.error) {
        toastr.error("ERROR", res.data.userMessage);
        return false;
      } else {
        toastr.success("SUCCESS", "Scan started.");
      }
      if(props.isVulnerabilities){
        dispatch({
          type: INVENTORY_DETAILS_FETCHEDV1,
          data: res.data.data,
        });
      }else{
       dispatch({
          type: LOOK_ALIKE_FETCHED,
          payload: res.data,
        });
      }
    });
  }
}

export const getDnsDetails = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getDnsDetails/${props}`)
      .then((response) => {
        dispatch({
          type: FETCH_DNS_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsdan = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}`)
      .then((response) => {
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const fetchSecurityHeader = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSecurityHeader/${props}`)
      .then((response) => {
        // console.log(response.data,"response.data")
        dispatch({
          type: SECURITY_HEADER,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: SECURITY_HEADER,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const getSubDomainDetails = (props, dispatch , page ,category ,status ,domainquery , domain) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}/delta?page=${page}&category=${category}&status=${status}&domainquery=${domainquery}&domain=${domain}`)
      .then((response) => {
        console.log(response,'response')
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsAction = (props, dispatch , page ,category ,status ,domainquery , domain ,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      .post(`${API_URL}/getSubDomainDetails/${props}/action?page=${page}&category=${category}&status=${status}&domainquery=${domainquery}&domain=${domain}`,data)
      .then((response) => {
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}/totals`)
      .then((response) => {
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
}

export const getSubDomainDetailsGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${session}`;
  if (props === undefined) {
    return null;
  } else {
    axios
      // .get(`${API_URL}/domainsquatting?domain=${props}`)
      .get(`${API_URL}/getSubDomainDetails/${props}/graph`)
      .then((response) => {
        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }

        dispatch({
          type: FETCH_SUCCESS_DNS_DETAILS_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
}