import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Import SVG assets
import DNS from "../../assets/images/DNS_Dashboard.svg";
import Known from "../../assets/images/VULNERAB_Dashboard.svg";
import SSL from "../../assets/images/SSLCERT_Dashboard.svg";
import Source from "../../assets/images/LEAKAGE_Dashboard.svg";
import Stolen from "../../assets/images/STOLENCRED_Dashboard.svg";
import Black from "../../assets/images/BLACKIP_Dashboard.svg";
import FakeMob from "../../assets/images/FAKESME_Dashboard.svg";
import FakeApp from "../../assets/images/FAKEAPP_Dashboard.svg";
import SUBDOM from "../../assets/images/SUBDOM_Dashboard.svg";
import Host from "../../assets/images/HOSTSDISC_Dashboard.svg";
import HostLocation from "../../assets/images/HOSTLOC_Dashboard.svg";
import CloudProviderSvg from "../../assets/images/CLOUDPROV_Dashboard.svg";
import OpenPorts from "../../assets/images/OPENPORTS_Dashboard.svg";
import OpertingSystem from "../../assets/images/OS_Dashboard.svg";
import networkService from "../../assets/images/NETWSERV_Dashboard.svg";
import AuditLogSvg from "../../assets/images/AuditLog_icon.svg";
import NotificationSvg from "../../assets/images/Notifications_icon.svg";
import ProfileSvg from "../../assets/images/Profile_Icon.svg";
import UserSvg from "../../assets/images/Users_icon.svg";
import BrandMention from "../../assets/images/brandMentions_icon.svg";
import pil from "../../assets/images/PII-Leakage_icon.svg";



const MENU_CONFIGS = {
  'settings-mode': {
    title: "Users",
    items: [
      { path: "/user/{id}", icon: UserSvg, label: "Users" },
      { path: "/profile", icon: ProfileSvg, label: "Profile" },
      { path: "/app-inbox", icon: NotificationSvg, label: "Notification" },
      { path: "/audit-log", icon: AuditLogSvg, label: "Audit Log" }
    ]
  },
  'remediationOverview': {
    title: "Remediation Mode",
    items: [
      { path: "/remediation-mode/overview", icon: UserSvg, label: "Overview" },
      { path: "/remediation-mode/task", icon: ProfileSvg, label: "Tasks" },
      { path: "/remediation-mode/tickets", icon: NotificationSvg, label: "Tickets" },
      { path: "/remediation-mode/trusted-user", icon: AuditLogSvg, label: "Trusted Users" },
      { path: "/remediation-mode/activity-log", icon: AuditLogSvg, label: "Activity Log" },
    ]
  },
  'security': {
    title: "Security Healthcheck",
    items: [
      { path: "/security/domains", icon: DNS, label: "Domain & DNS Health Check", isDefault: true },
      { path: "/security/sub-domain", icon: SUBDOM, label: "Subdomain" },
      { path: "/security/known-vulnerabilities", icon: Known, label: "Known Vulnerabilities" },
      { path: "/security/ssl-certificates", icon: SSL, label: "SSL Certificates" },
      { path: "/security/web-technology", icon: "/assets/images/image-gallery/web_technologies.svg", label: "Web Technologies" }
    ]
  },
  'brand': {
    title: "Brand Security",
    items: [
      { path: "/brand/mobile-apps", icon: FakeApp, label: "Mobile Application", isDefault: true },
      { path: "/brand/look-alike", icon: "/assets/images/image-gallery/Icon_1.svg", label: "Look - Alike" },
      { path: "/brand/social-media", icon: FakeMob, label: "Social Media Profiles" },
      { path: "/brand/brand-mention", icon: BrandMention, label: "Brand Mention" }
    ]
  },
  'services': {
    title: "Valkyrie Services",
    items: [
      { path: "/services#identify", icon: "/assets/images/Identify_Valkyrie.svg", label: "Identify" },
      { path: "/services#protect", icon: "/assets/images/Protect_Valkyrie.svg", label: "Protect" },
      { path: "/services#detect", icon: "/assets/images/Detect_Valkyrie.svg", label: "Detect" },
      { path: "/services#respond", icon: "/assets/images/Respond_Valkyrie.svg", label: "Respond" },
      { path: "/services#recover", icon: "/assets/images/Recover_Valkyrie.svg", label: "Recover" },
      { path: "/services#custom", icon: "/assets/images/Custom_Valkyrie.svg", label: "Custom Services" }
    ],
    menuIcon: "/assets/images/valkyrie_service.svg"
  },
  'assets': {
    title: "Asset Monitoring",
    items: [
      { path: "/assets/#hostsDiscovered", icon: Host, label: "Hosts Discovered" },
      { path: "/assets/#hostsLocation", icon: HostLocation, label: "Hosts Locations" },
      { path: "/assets/#cloudProvider", icon: CloudProviderSvg, label: "Cloud Provider" },
      { path: "/assets/#openPort", icon: OpenPorts, label: "Open Ports" },
      { path: "/assets/#operatingSystem", icon: OpertingSystem, label: "Operating Systems" },
      { path: "/assets/#networkService", icon: networkService, label: "Network Services" }
    ],
    menuIcon: "/assets/images/Assets_IconMain_.svg"
  },
  'breach': {
    title: "Breach Detection",
    items: [
      { path: "/breach/stolen-credentials", icon: Stolen, label: "Stolen Credentials", isDefault: true },
      { path: "/breach/malware-credentials", icon: "/assets/images/image-gallery/malware_infected_computers.svg", label: "Malware Infected Computers" },
      { path: "/breach/source-code", icon: Source, label: "Source Code Leakage" },
      { path: "/breach/leaked-sessions", icon: "/assets/images/image-gallery/leaked_session.svg", label: "Leaked Session" },
      { path: "/breach/cloud-bucket", icon: "/assets/images/image-gallery/open_cloud_buckets.svg", label: "Open Cloud Buckets" },
      { path: "/breach/ip-blacklist", icon: Black, label: "IP Blacklists" },
      { path: "/breach/pil", icon: pil, label: "Personal Information" }
    ]
  }
};

const MenuItem = ({ path, icon, label, isActive, id }) => {
  const finalPath = path.includes("{id}") ? path.replace("{id}", id) : path;
  return (
    <li>
      <Link to={finalPath} className={`text_img_sidebar ${isActive ? 'active_sidebar_check' : ''}`}>
        <div className="circle_button">
          <div className="inner_box">
            <img src={icon} className="img-fluid" alt={label} />
          </div>
        </div>
        <span>{label}</span>
      </Link>
    </li>
  );
};

const Sidenav = ({ type }) => {

  const location = useLocation();
  const { pathname, hash } = location;
  const mode = useSelector(state => state?.summary?.modes?.data || "assessment-mode");
  const id = useSelector(state => state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id);

  const getMenuConfig = () => {
    if (mode === 'settings-mode') {
      return MENU_CONFIGS['settings-mode'];
    }

    if (type?.includes('security') || ['/domains', '/known-vulnerabilities', '/ssl-certificates', '/web-technology'].includes(type)) {
      return MENU_CONFIGS.security;
    }
    if (mode === 'remediation-mode') {
      return MENU_CONFIGS.remediationOverview;
    }

    if (type?.includes('brand') || ['/mobile-apps', '/look-alike', '/social-media'].includes(type)) {
      return MENU_CONFIGS.brand;
    }
    if (type?.includes('services')) {
      return MENU_CONFIGS.services;
    }
    if (type?.includes('assets')) {
      return MENU_CONFIGS.assets;
    }
    return MENU_CONFIGS.breach;
  };

  const menuConfig = getMenuConfig();


  const isItemActive = (itemPath) => {
    const [itemBasePath, itemHash] = itemPath.split('#');
    const [currentBasePath, currentHash] = pathname.split('#');
    if (itemHash) {
      return currentBasePath === itemBasePath && hash === `#${itemHash}`;
    }

    if (itemPath.includes('/user/')) {
      return pathname.startsWith('/user/');
    }

    return pathname === itemPath ||
      (itemPath.includes(pathname) && pathname !== '/') ||
      (pathname === `/${type}` && itemPath.includes(type) && menuConfig.items.find(i => i.path === itemPath)?.isDefault);
  };

  return (
    <div className="side_bar">
      <ul>
        <li>
          <a href="javascript:void(0)" className="menu_btnside">
            <img
              src={menuConfig.menuIcon || "/assets/images/image-gallery/MenuIcon.png"}
              className="img-fluid"
              alt="menu"
            />
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" className="security_check_1">
            <span>{menuConfig.title}</span>
          </a>
        </li>
        {menuConfig.items.map(item => (
          <MenuItem
            key={item.path}
            {...item}
            id={id}
            isActive={isItemActive(item.path)}
          />
        ))}
        <li>
          <a href="javascript:void(0)" className="security_check_2">
            <span>{menuConfig.title}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidenav;