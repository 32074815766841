
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { getDnsDetails } from "../../actions/sub-domain";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import SideNav from '../resuableComponent/sidenav';
import Header from '../resuableComponent/header';
import Loader from "../resuableComponent/loader";
import Chart from 'react-apexcharts';
import { getSubDomainDetails, getSubDomainDetailsTotal, removeRemediationData, addRemediationData, getSubDomainDetailsGraph, getSubDomainDetailsAction } from "../../actions/sub-domain";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { API_URL } from "../../utils/constant";



const RemediationActivityLog = () => {

  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [filterData, setfilterData] = useState("all");
  const [domainFilter, setDomainFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem('vendorName');
  const queryParameters = new URLSearchParams(window.location.search);
  const latest = queryParameters.get("latest");
  const auth = useSelector((state) => state?.auth?.user);
  const location = useLocation();
  const [checkPath, setCheckPath] = useState('');
  const modeValue = new URLSearchParams(location?.search).get('mode');
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const statusParam = params.get('status');
    setfilterData(statusParam);
  }, []);

  useEffect(() => {
    setCheckPath(location.search)
  }, [location.search])

  const id = useSelector(state => { return state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id });
  const client = useSelector(
    (state) =>
      state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  let domain = client?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  console.log(domain, 'domain')
  if (domain?.charAt(domain.length - 1) == "/") {
    domain = [domain.substr(0, domain.length - 1)];
    // domain.unshift("all");
  } else {
    domain = [domain];
    // domain.unshift("all");
  }
  if (client?.domains && client.domains.length > 0) {
    domain = client.domains;
    // domain.unshift("all");
  }
  let dnsDetailsArray = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails?.data?.data
  );
  console.log(dnsDetailsArray, 'dnsDetailsArray')
  let dnsDetailsTotal = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails?.data
  );
  let oldDnsDetails = useSelector(
    (state) => state?.subDomain?.subDomainDetailGraph?.data
  );
  const addModal = () => {
    setModal("Form Wizard modal");
  };

  let distinctFilter = ["all", "active", "inactive", "dangling"];


  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);


  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/getSubDomainDetails/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sub-Domain Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };


  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };


  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      getSubDomainDetailsAction(
        id, dispatch, page, category, filterData, search, domainFilter, data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: 'resetAction',
      subDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    getSubDomainDetailsAction(
      id, dispatch, page, category, filterData, search, domainFilter, data
    );
    setSelectedIds([]);
  }

  const submit = () => {
    const data = {
      action: action,
      subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    getSubDomainDetailsAction(
      id, dispatch, page, category, filterData, search, domainFilter, data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote('');
    setSelectedIds([]);
  };

  useEffect(() => {
    getSubDomainDetails(id, dispatch, page, category, filterData, search, domainFilter);
  }, [id, dispatch, page, category, filterData, search, domainFilter]);

  useEffect(() => {
    setTotalItems(dnsDetailsTotal?.totalDomains);
  }, [dnsDetailsTotal?.totalDomains]);

  useEffect(() => {
    getSubDomainDetailsGraph(id, dispatch)
  }, [id, dispatch])

  useEffect(() => {
    getSubDomainDetailsTotal(id, dispatch)
  }, [id, dispatch])


  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(() => {
    if (dnsDetailsArray) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [dnsDetailsArray]);



  let mapData = dnsDetailsArray;
  mapData = mapData;
  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, dispatch, id).then((res) => {
              getSubDomainDetails(id, dispatch, page, category, filterData, search, domainFilter);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName": 'deltasubdomaindetails',
      "organisationId": id,
      "recordId": row?._id,
      "moduleName": 'sub_domain'
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, dispatch).then((res) => {
              getSubDomainDetails(id, dispatch, page, category, filterData, search, domainFilter);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  return (
    <div>
    </div>
  );
}

export default RemediationActivityLog;