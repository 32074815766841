import { OVERVIEW_LIST } from "../actions";

const initialState = {
  loading: true,
  response: null,
  list: null,
  data: null,
  fetchedData: {},

};
export default function (state = initialState, action) {
  switch (action.type) {
    case OVERVIEW_LIST:
      return {
        ...state,
        list: action.data,
        loading: true
      };
    default:
      return state;
  }
}
