import { combineReducers } from 'redux';
import { reducer as toastr } from "react-redux-toastr";

import settings from './settings';
import auth from './auth';
import customer from './customer';
import inventory from './inventrory';
import users from './users';
import vulnerability from './vulnerability';

import report from "./report";
import partner from "./partner";
import leakedCredential from "./leakedCredential";
import summary from "./summary";

import lookAlike from "./look-alike";
import fakeMobileApp from "./fakeMobileApp";
import subDomain from "./sub-domain";
import inventoryDetails from "./inventoryDetails";
import openCloud from "./openCloud";
import bucketDetails from "./bucket-details";
import leakedCode from "./leakedCode";
import sourceCode from "./blank-page";
import apiScan from "./apiScan";
import scheduler from "./scheduler";
import cloudAccount from "./cloudAccount";
import integration from './integration';
import pastebin from "./pastebin";
import remediationMode from './remediationMode';


export default combineReducers({
    settings,
    auth,
    report,
    partner,
    leakedCredential,
    customer,
    inventory,
    users,
    vulnerability,
    summary,
    lookAlike,
    fakeMobileApp,
    subDomain,
    inventoryDetails,
    openCloud,
    bucketDetails,
    leakedCode,
    sourceCode,
    apiScan,
    scheduler,
    toastr,
    cloudAccount,
    integration,
    pastebin,
    remediationMode
});