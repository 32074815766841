import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import { LEAKEDCREDS_REQUESTS_FETCHED, LEAKED_SESSION_FETCHED_GRAPH, MALWARE_CREDENTIALS_FETCHED_GRAPH,PII_DETAILS_TOTAL,PII_DETAILS_GRAPH, LEAKEDCREDS_REQUESTS_FETCHED_GRAPH, MALWARE_CREDENTIALS_FETCHED, LEAKED_SESSION_FETCHED, GET_PHISHING_PAGE, PII_DETAILS} from "./index";
import { toastr } from 'react-redux-toastr';


export const fetchLeakedCredentials = (
  props,
  dispatch,
  page,
  category,
  type,
  emailquery,
  domainSelected,
  domains
) => {
  if (props) {
    let url = `${API_URL}/dehashed/search/${props}/delta?page=${page}&category=${category}&type=${type}&emailquery=${emailquery}`;
    if (domainSelected === 'true' || domainSelected === 'all') {
      url += `&compromiseEmployee=${domainSelected}`;
    } else if (domainSelected && domains) {
      url += `&${domainSelected}=${domains}`;
    }
    axios.get(url)
      .then((res) => {
        dispatch({
          type: LEAKEDCREDS_REQUESTS_FETCHED,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error('Error fetching leaked credentials:', error);
      });
  }
};

export const fetchLeakedCredentialsAction = ( 
  props,
  dispatch,
  page,
  category,
  data
  ) => {
  if(props){
    axios
      .post(`${API_URL}/dehashed/search/${props}/action?page=${page}&category=${category}`, data)
      .then((res) => {
        dispatch({
          type: LEAKEDCREDS_REQUESTS_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchLeakedCredentialsGraph = (props, dispatch) => {
  if (props) {
    axios.get(`${API_URL}/dehashed/search/${props}/graph`).then((res) => {
      dispatch({
        type: LEAKEDCREDS_REQUESTS_FETCHED_GRAPH,
        payload: res.data,
      });
    });
  }
};


export const fetchMalwareCredentials = (
  props,
  dispatch,
  page,
  category,
  userquery,
  domainSelected,
  domains
) => {
  if (props) {
    let url = `${API_URL}/getMalwareCredentials/${props}/delta?page=${page}&category=${category}&userquery=${userquery}`;
    if (domainSelected === 'true' || domainSelected === 'all') {
      url += `&compromiseEmployee=${domainSelected}`;
    } else if (domainSelected && domains) {
      url += `&${domainSelected}=${domains}`;
    }
    axios.get(url)
      .then((res) => {
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error('Error fetching malware credentials:', error);
      });
  }
};

export const fetchMalwareCredentialsAction = (props, dispatch,page,category,data) => {
  if (props) {
    axios
      .post(`${API_URL}/getMalwareCredentials/${props}/action?page=${page}&category=${category}`, data)
      .then((res) => {
        console.log(res,'res')
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchMalwareCredentialsGraph = (props, dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/getMalwareCredentials/${props}/graph`, {})
      .then((res) => {
        dispatch({
          type: MALWARE_CREDENTIALS_FETCHED_GRAPH,
          payload: res.data,
        });
      });
  }
}


export const fetchLeakedSession = (
  props,
  dispatch,
  page,
  status,
  name,
  category,
  domainSelected,
  domains
) => {
  if (props) {
    let url = `${API_URL}/leaked-sessions/${props}/delta?page=${page}&status=${status}&name=${name}&category=${category}`;
    if (domainSelected && domains) {
      url += `&${domainSelected}=${domains}`;
    }
    axios.get(url)
      .then((res) => {
        dispatch({
          type: LEAKED_SESSION_FETCHED,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error('Error fetching leaked session:', error);
      });
  }
};

export const fetchLeakedSessionGraph = (props, dispatch ) => {
  if(props){
    axios
      .get(`${API_URL}/leaked-sessions/${props}/graph`, {})
      .then((res) => {
        dispatch({
          type: LEAKED_SESSION_FETCHED_GRAPH,
          payload: res.data,
        });
      });
  }
}

export const fetchLeakedSessionAction = (props, dispatch ,page ,category, data) => {
  if (props) {
    axios
      .post(`${API_URL}/leaked-sessions/${props}/action?page=${page}&category=${category}`,data)
      .then((res) => {
        dispatch({
          type: LEAKED_SESSION_FETCHED,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchPhishingPage = (props, dispatch) => {
  if(props){
    axios
      .get(`${API_URL}/getPhishingPage/${props}`, {})
      .then((res) => {
        dispatch({
          type: GET_PHISHING_PAGE,
          payload: res.data,
        });
        
      });
  }
}

export const fetchPiiDetails = (props, dispatch, page, category, email_search_query, startDate, endDate, limit) => {
  if (props) {
    axios
      .get(`${API_URL}/pii-details/${props}/delta?page=${page}&limit=${limit}&category=${category}&email_search_query=${email_search_query}&from=${startDate}&till=${endDate}`)
      .then((res) => {
        dispatch({
          type: PII_DETAILS,
          payload: res.data,
        });
      });
  }
};

export const piiDelete = (props, dispatch, id) => {
  if (props) {
    axios
      .delete(`${API_URL}/pii-details/${props}/${id}/delete`)
      .then((res) => {
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
          fetchPiiDetails(props, dispatch, 1, 'all', '', '', '')
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const piiUpdateById = (props, dispatch, data, clear) => {
  if (props) {
    axios
      .patch(`${API_URL}/pii-details/${props}/update`, data)
      .then((res) => {
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
          fetchPiiDetails(props, dispatch, 1, 'all', '', '', '')
          clear()
        } else {
          toastr.error("Error", res.data.userMessage);
        }
      });
  }
};

export const fetchPiiDetailsTotal = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/pii-details/${props}/totals`)
      .then((res) => {
        dispatch({
          type: PII_DETAILS_TOTAL,
          payload: res.data,
        });
      });
  }
};

export const fetchPiiDetailsGraph = (props, dispatch) => {
  if (props) {
    axios
      .get(`${API_URL}/pii-details/${props}/graph`)
      .then((res) => {
        dispatch({
          type: PII_DETAILS_GRAPH,
          payload: res.data,
        });
      });
  }
};

export const fetchPiiDetailsAction = (props, dispatch, page, category, email_search_query, data) => {
  if (props) {
    axios
      .post(`${API_URL}/pii-details/${props}/action?page=${page}&category=${category}&email_search_query=${email_search_query}`, data)
      .then((res) => {
        dispatch({
          type: PII_DETAILS,
          payload: res.data,
        });
        if (res.data.status == 200) {
          toastr.success("Success", res.data.userMessage);
        } else {
          toastr.success("Error", res.data.userMessage);
        }
      });
  }
};
