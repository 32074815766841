import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchPiiDetails,
  fetchPiiDetailsAction,
  fetchPiiDetailsTotal,
  fetchPiiDetailsGraph,
} from "../../actions/leakedCredentials";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $ from "jquery";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";

const PersonalInformation = () => {
  const { orgId } = useParams();
  const id = useSelector(
     (state) =>
       state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
   );

  const auth = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const [toggleArray, setToggleArray] = useState({});
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const vendorName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');
  const [limit, setLimit] = useState(10);
  

  const state = useSelector(
    (state) => state?.leakedCredential?.piidetails?.data?.data
  );
  const totalItem = useSelector(
    (state) => state?.leakedCredential?.piidetails?.data
  );
  const totalRecords = useSelector(
    (state) => state?.leakedCredential?.piiTotal?.data
  );
  let oldDnsDetails = useSelector(
    (state) => state.leakedCredential?.piiGraph?.data
  );

  let ipArray = [];
  if (oldDnsDetails?.scanDetails) {
    ipArray.push(oldDnsDetails?.scanDetails || 0);
  }

  useEffect(() => {
    fetchPiiDetails(id, dispatch, page, category, search, startDate, endDate, limit);
  }, [id, dispatch, page, category, search, startDate, endDate, limit]);

  useEffect(() => {
    fetchPiiDetailsTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchPiiDetailsGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(totalItem?.totalPersonalInformationLeakage);
  }, [totalItem?.totalPersonalInformationLeakage]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event === "markAsRemidiated") {
      $.confirm({
        title: "Are you sure?",
        content: "Do you really want to mark this as remediated?",
        type: "green", // Customize the color as needed
        buttons: {
          ok: {
            text: "Yes, Mark as Remediated",
            btnClass: "btn-success",
            keys: ["enter"],
            action: function () {
              let ids = [];
              ids.push(_id);
              const data = {
                action: "markAsRemediated",
                leakedPersonalInformationIds: selectedIds.length === 0 ? ids : selectedIds,
                actionNote: "",
                for: "forever",
              };
              fetchPiiDetailsAction(id, dispatch, page, category, search, data);
              setSelectedValue("pleaseSelect");
              setActionNote("");
              setSelectedIds([]);
            },
          },
          cancel: function () {
          },
        },
      });
    }

  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedPersonalInformationIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchPiiDetailsAction(id, dispatch, page, category, search, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      leakedPersonalInformationIds:
        selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchPiiDetailsAction(id, dispatch, page, category, search, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  let mapData = state;

  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  let clientName = "";

  if (JSON.parse(localStorage.getItem("OrgName"))) {
      if (sessionStorage.getItem('vendorId')) {
        clientName = sessionStorage.getItem('vendorName')
    } else {
      clientName = JSON.parse(localStorage.getItem("OrgName"));
    }
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/pii-details/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${clientName?.replace(/\s+/g, '-')}Personal Information.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleIcon = (_id, index, value) => {
    let val = toggleArray;
    let id = _id + "_" + index;
    if (val[id]) {
      $("#" + _id + "_" + index + "_password").html("******");
      $("#" + _id + "_" + index + "_password_icon").attr("class", "fa fa-eye");
      // fa fa-eye-slash
      delete val[id];
      setToggleArray({ ...val });
    } else {
      $("#" + _id + "_" + index + "_password").html(value);
      $("#" + _id + "_" + index + "_password_icon").attr(
        "class",
        "fa fa-eye-slash"
      );
      val[id] = true;
      setToggleArray({ ...val });
    }
  };

   const [containerClass, setContainerClass] = useState("data-container");
    useEffect(() => {
      if (state?.length > 0) {
        setTimeout(() => {
          setContainerClass("data-container active");
        }, 300);
      }
    }, [state]);
  return (
    <div className="data-container active">
       <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
            {/* <div className="body" style={{ minHeight: "440px" }}>
              <div className="row text-center">
                <div className="col-12 pb-4 pt-4">
                  <label className="mb-0">Records Found</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {totalRecords?.totalPersonalInformationLeakage || 0}
                  </h4>
                </div>
              </div>

              <div className="form-group">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Address{" "}
                  <span className="float-right">
                    {totalRecords?.address_count}
                  </span>
                </label>
              </div>

              <div className="form-group">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Ip{" "}
                  <span className="float-right">
                    {totalRecords?.ip_address_count}
                  </span>
                </label>
              </div>

              <div className="form-group">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Phone Number{" "}
                  <span className="float-right">
                    {totalRecords?.phone_count}
                  </span>
                </label>
              </div>

              <div className="form-group">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  UserName{" "}
                  <span className="float-right">
                    {totalRecords?.username_count}
                  </span>
                </label>
              </div>
            </div> */}
          <div className="card">
            <div className="body" style={{ minHeight: "440px" }}>
              <h6>Previous scan Details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={oldDnsDetails?.scanDetails}
                  color={["#b0f127"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setSelectedIds([]);

              setSearch("");
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
          <div className="row">
            <div className="col-sm-4">
              <div
                data-test="datatable-res-input"
                class="mdb-datatable-filter flex-row"
              >
                <input
                  class="form-control form-control-sm ml-0 my-1"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                />
              </div>
            </div>
            <div className="col-sm-4 d-flex align-items-end">
              <label htmlFor="startDate">From:</label>
              <input
                type="date"
                className="ml-1 form-control form-control-sm"
                id="startDate"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setPage(1);
                }}
              />
            </div>
            <div className="col-sm-4 d-flex align-items-end">
              <label htmlFor="endDate">Till:</label>
              <input
                type="date"
                className="ml-1 form-control form-control-sm"
                id="endDate"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setPage(1);
                }}
              />
            </div>
            <div className="col-sm-4">
              {selectedIds.length > 1 && category === "all" && (
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      onChange={(event) => {
                        setAction(event.target.value);
                        snooze("", event.target.value);
                      }}
                    >
                      <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                      <MenuItem
                        value="riskAccepted"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        Risk Accepted
                      </MenuItem>
                      <MenuItem
                        value="markAsFalsePositive"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        False Positive
                      </MenuItem>
                      <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {selectedIds.length > 1 &&
                (category == "false-positive" ||
                  category == "risk-accepted") && (
                  <button
                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                    onClick={() => Unsnoozed("")}
                  >
                    Unsnooze
                  </button>
                )}
            </div>
          </div>
          <div className="row_boxes row_boxes_table">
                <div className="table_box table_box_full">
                <table className="table">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={selectedIds == 0 ? false : multiCheckTrue}
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                      <th>Date</th>
                      <th>Breached Database</th>
                      <th>Email Address</th>
                      <th>Username</th>
                      <th>Contact Number</th>
                      <th>IP Address</th>
                      <th>Residential Address</th>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      return (
                        <tr index={"piii_" + index}>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                              <td>
                                <input
                                  onClick={() => singleClick(row?._id)}
                                  checked={selectedIds?.includes(row?._id)}
                                  type="checkbox"
                                  className="checkUncheck"
                                />
                              </td>
                            )}
                          <td>
                            {" "}
                            {row?.updatedAt
                              ? moment(row?.createdAt).format(
                                "DD-MM-YYYY"
                              )
                              : row?.createdAt
                                ? moment(row?.createdAt).format(
                                  "DD-MM-YYYY"
                                )
                                : "NA"}
                          </td>
                          <td style={{ whiteSpace: "unset", width: "100px" }}>
                            <span>
                              {row?.database_name}
                              <br />
                            </span>
                          </td>
                          <td style={{ whiteSpace: "unset", width: "100px" }}>
                            {row?.email || "NA"}
                          </td>
                          <td>
                            <span>
                              {row?.username || "NA"}
                              <br />
                            </span>
                          </td>
                          <td>
                            <span>
                              {Array.isArray(row?.phone)
                                ? row?.phone?.[0] || "NA"
                                : row?.phone && row?.phone !== ""
                                  ? row?.phone
                                  : "NA"}
                              <br />
                            </span>
                          </td>
                          <td>
                            <span>
                              {row?.ip_address || "NA"}
                              <br />
                            </span>
                          </td>
                          <td>
                            {/* {row?.address || "NA"} */}
                            {typeof row?.address === "object"
                              ? row?.address?.home?.[0] || "NA"
                              : row?.address && row?.address !== ""
                                ? row?.address
                                : "NA"}
                          </td>
                          {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(row?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                            category === "false-positive") && (
                              <td>
                                <button
                                  className="btn btn-primary btn-sm mr-1 Unsnooze"
                                  onClick={() => Unsnoozed(row?._id)}
                                >
                                  Unsnooze
                                </button>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={limit}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
                  </div>
                  </div>
                  </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => { setForValue(e.target.value); setPage(1) }}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModal("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};
export default PersonalInformation;
