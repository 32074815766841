export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const DASHBOARD_FETCHED = "DASHBOARD_FETCHED";
export const REPORT_REQUESTS_FETCHED = "REPORT_REQUESTS_FETCHED";
export const PARTNER_REQUESTS_FETCHED = "PARTNER_REQUESTS_FETCHED";
export const LEAKEDCREDS_REQUESTS_FETCHED = "LEAKEDCREDS_REQUESTS_FETCHED";
export const LEAKEDCREDS_REQUESTS_FETCHED_GRAPH = "LEAKEDCREDS_REQUESTS_FETCHED_GRAPH";
export const LEAKEDCODE_REQUESTS_FETCHED = "LEAKEDCODE_REQUESTS_FETCHED";

export const LOOK_ALIKE_FETCHED = "LOOK_ALIKE_FETCHED";
export const LOOK_ALIKE_FETCHED_BY_ID = "LOOK_ALIKE_FETCHED_BY_ID";
export const CUSTOMERS_FETCHED = "CUSTOMERS_FETCHED";
export const VENDORS_FETCHED = "VENDORS_FETCHED";

export const CSPM_FETCHED = "CSPM_FETCHED";

export const DOMAIN_SQUATING = "DOMAIN_SQUATING";
export const DOMAIN_SQUATING_GRAPH = "DOMAIN_SQUATING_GRAPH";

export const CUSTOMER_CREATED = "CUSTOMER_CREATED";
export const VENDOR_CREATED = "VENDOR_CREATED";
export const CSPM_CREATED = "CSPM_CREATED";

export const CODE_CREATED = "CODE_CREATED";

export const CUSTOMERS_UPDATED = "CUSTOMERS_UPDATED";
export const CREATING_CUSTOMER = "CREATING_CUSTOMER";
export const CREATING_VENDOR = "CREATING_VENDOR";

export const CREATING_CSPM = "CREATING_CSPM";

export const CREATING_CODE_RECORD = "CREATING_CODE_RECORD";

export const INVENTORY_FETCHED = "INVENTORY_FETCHED";
export const INVENTORY_FETCHED_GRAPH = "INVENTORY_FETCHED_GRAPH";
export const SINGLE_INVENTORY_FETCHED = "SINGLE_INVENTORY_FETCHED";
export const FETCHING_SINGLE_INVENTORY = "FETCHING_SINGLE_INVENTORY";
export const SINGLE_INVENTORY_DATA_FETCHED = "SINGLE_INVENTORY_DATA_FETCHED";
export const EDIT_CUSTOMER_FETCHED = "EDIT_CUSTOMER_FETCHED";
export const UPDATING_CUSTOMER = "UPDATING_CUSTOMER";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const DELETING_CUSTOMER = "DELETING_CUSTOMER";

export const INVENTORY_FETCHED_DISCOVERY = "INVENTORY_FETCHED_DISCOVERY";
export const MODE_SETTING = "MODE_SETTING";
export const ASSETS_INTERNAL_PAGES = "ASSETS_INTERNAL_PAGES";



export const CUSTOMER_DELETED = "CUSTOMER_DELETED";

export const FETCHING_CUSTOMERS = "FETCHING_CUSTOMERS";
export const FETCHING_VENDORS = "FETCHING_VENDORS";

export const FETCHING_CSPM = "FETCHING_CSPM";

export const FETCHING_THREAT_ANALYIST = "FETCHING_THREAT_ANALYIST";
export const FETCHING_CODE = "FETCHING_CODE";

export const GETING_DATA_BY_LOCALSTORAGE = "GETING_DATA_BY_LOCALSTORAGE";
export const USERS_FETCHED = "USERS_FETCHED";
export const USERS_CREATED = "USERS_CREATED";
export const CREATING_USER = "CREATING_USER";
export const EDIT_USER_FETCHED = "EDIT_USER_FETCHED";
export const LOGED_IN_USER = "LOGED_IN_USER";
export const UPDATING_USER = "UPDATING_USER";
export const USER_UPDATED = "USER_UPDATED";
export const DELETING_USER = "DELETING_USER";
export const USER_DELETED = "USER_DELETED";
export const FETCHING_USERS = "FETCHING_USERS";

// summary
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const SUMMARY_FETCHED = "SUMMARY_FETCHED";

// fake-mobile-app
export const FETCH_MOBILE_APP = "FETCH_MOBILE_APP";
export const FETCH_MOBILE__GRAPH = "FETCH_MOBILE__GRAPH";
// export const FETCH_MOBILE_APP = "FETCH_MOBILE_APP";

export const PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED";
export const PASSWORD_RESET_DONE = "PASSWORD_RESET_DONE";

// sub domain
export const FETCH_SUBDOMAIN_DETAIL = "FETCH_SUBDOMAIN_DETAIL";
export const SUBDOMAIN_SCAN_STARTED = "SUBDOMAIN_SCAN_STARTED";
export const SUBDOMAIN_FETCHED = "SUBDOMAIN_FETCHED";
export const SUBDOMAIN_FETCHED_EXP = "SUBDOMAIN_FETCHED_EXP";
export const SUBDOMAIN_FETCHED_SSL = "SUBDOMAIN_FETCHED_SSL";
export const SUBDOMAIN_FETCHED_SSL_TOTAL = "SUBDOMAIN_FETCHED_SSL_TOTAL";
export const SUBDOMAIN_FETCHED_SSL_GRAPH = "SUBDOMAIN_FETCHED_SSL_GRAPH";
export const SUBDOMAIN_FETCHED_TOTAL = "SUBDOMAIN_FETCHED_TOTAL";
export const SUBDOMAIN_FETCHED_GRAPH = "SUBDOMAIN_FETCHED_GRAPH";

export const FETCH_DNS_DETAILS = "FETCH_DNS_DETAILS";
export const FETCH_SUCCESS_DNS_DETAILS = "FETCH_SUCCESS_DNS_DETAILS";
export const FETCH_SUCCESS_DNS_DETAILS_TOTAL = "FETCH_SUCCESS_DNS_DETAILS_TOTAL";
export const FETCH_SUCCESS_DNS_DETAILS_GRAPH = "FETCH_SUCCESS_DNS_DETAILS_GRAPH";

export const SCAN_LIST = "SCAN_LIST";

// inventoryDetails
export const INVENTORY_DETAILS_FETCHED = "INVENTORY_DETAILS_FETCHED";
export const INVENTORY_DETAILS_FETCHEDV1 = "INVENTORY_DETAILS_FETCHEDV1";
export const ALL_INVENTORY_FETCHED = "ALL_INVENTORY_FETCHED"

// Blank Page
export const SOURCE_CODE_FETCHED = "SOURCE_CODE_FETCHED";

//Social Media
export const SOCIAL_MEDIA_PAGE_FETCHED = "SOCIAL_MEDIA_PAGE_FETCHED";


export const API_SCAN_FETCHED = "API_SCAN_FETCHED";
export const API_SCAN_CREATED = "API_SCAN_CREATED";
export const API_SCAN_DETAILS_FETCHED = "API_SCAN_DETAILS_FETCHED";

export const SUSPICIOUS_CLOUD_GRAPH = "SUSPICIOUS_CLOUD_GRAPH";

export const SCHEDULER_FETCHED = "SCHEDULER_FETCHED";
export const SCHEDULER_SUBDOMAIN_FETCHED = "SCHEDULER_SUBDOMAIN_FETCHED";


export const FETCHING_ORGANISATION = "FETCHING_ORGANISATION";

export const FETCH_DARK_WEB_MENTION = "FETCH_DARK_WEB_MENTION";
export const FETCH_FAKE_SOCIAL_MEDIA = "FETCH_FAKE_SOCIAL_MEDIA";
export const FETCH_FAKE_SOCIAL_MEDIA_TOTAL = "FETCH_FAKE_SOCIAL_MEDIA_TOTAL";
export const FETCH_FAKE_SOCIAL_MEDIA_EXCEL = "FETCH_FAKE_SOCIAL_MEDIA_EXCEL";


export const REPORT_DOWNLOAD = "REPORT_DOWNLOAD";

export const MAIN_SCREEN_SUMMARY_FETCHED = "MAIN_SCREEN_SUMMARY_FETCHED";

export const FETCH_CVE_DETAILS = "FETCH_CVE_DETAILS";
export const FETCH_CVE_DETAILS_GRAPH = "FETCH_CVE_DETAILS_GRAPH";
export const FETCH_CVE_DETAILS_TOTAL = "FETCH_CVE_DETAILS_TOTAL";


export const DASHBOARD_SUMMARY_FETCHED = "DASHBOARD_SUMMARY_FETCHED";
export const REPORT_PANEL_FETCHED = "REPORT_PANEL_FETCHED";
export const REPORT_SETTING = "REPORT_SETTING";
export const PENDING_COUNT  = "PENDING_COUNT";
export const VENDOR_SUMMARY_FETCHED = "VENDOR_SUMMARY_FETCHED";

export const CREATE_CLOUD_ACCOUNT = "CREATE_CLOUD_ACCOUNT";
export const GET_SCAN_BY_ACCOUNT_ID = "GET_SCAN_BY_ACCOUNT_ID";
export const GET_RESULT_BY_ACCOUNT_ID = "GET_RESULT_BY_ACCOUNT_ID";
export const GET_SCAN_DETAILS = "GET_SCAN_DETAILS";

export const CREATE_INTEGRATION = "CREATE_INTEGRATION";
export const FETCH_INTEGRATION = "FETCH_INTEGRATION";
export const ALERTS_FETCHED = "ALERTS_FETCHED";
export const FETCHING_ALERTS = "FETCHING_ALERTS";

export const MALWARE_CREDENTIALS_FETCHED = "MALWARE_CREDENTIALS_FETCHED";
export const MALWARE_CREDENTIALS_FETCHED_GRAPH = "MALWARE_CREDENTIALS_FETCHED_GRAPH";
export const LEAKED_SESSION_FETCHED = "LEAKED_SESSION_FETCHED";
export const LEAKED_SESSION_FETCHED_GRAPH = "LEAKED_SESSION_FETCHED_GRAPH"
// export const LEAKED_SESSION_FETCHED_GRAPH = "LEAKED_SESSION_FETCHED_GRAPH";;

export const PASTEBIN_RECORD_FETCHED = "PASTEBIN_RECORD_FETCHED";
export const GET_PHISHING_PAGE = "GET_PHISHING_PAGE";

export const FETCH_WEB_DEFACEMENT = "FETCH_WEB_DEFACEMENT";
export const TWO_FACTOR_ENABLED = "TWO_FACTOR_ENABLED";

export const SECURITY_HEADER = "SECURITY_HEADER";

export const UPDATE_COUNTER = "UPDATE_COUNTER";
export const PII_DETAILS = "PII_DETAILS";

export const SIDE_BAR_DATA_FETCHED = "SIDE_BAR_DATA_FETCHED";
export const REMEDIATION_DATA_ADDED = "REMEDIATION_DATA_ADDED";
export const REMEDIATION_DATA_REMOVED = "REMEDIATION_DATA_REMOVED";
export const REMEDIATION_DATA_GET = "REMEDIATION_DATA_GET";
export const REMEDIATION_DATA_TOTAL = "REMEDIATION_DATA_TOTAL";
export const REMEDIATION_LIST = "REMEDIATION_LIST";
export const GET_PROCESS_LIST = "GET_PROCESS_LIST";
export const GET_PROCESS_CONTAINERS = "GET_PROCESS_CONTAINERS";
export const GET_PROCESS_CONTAINERS_BODY_LIST = "GET_PROCESS_CONTAINERS_BODY_LIST";


export const ALL_PORT = "ALL_PORT";
export const ALL_COUNTRY = "ALL_COUNTRY";
export const ALL_CLOUD = "ALL_CLOUD";

// overview

export const OVERVIEW_LIST = "OVERVIEW_LIST";



