import axios from "axios";
import { API_URL} from "../utils/constant";
import { OVERVIEW_LIST } from ".";
import { toastr } from "react-redux-toastr";


export const getOverviewList = (orgId, dispatch, page) => {
  if (orgId) {
    axios
      .get(`${API_URL}/ticket-system/${orgId}/overview?page=${page}&limit=10`)
      .then((res) => {
        dispatch({
          type: OVERVIEW_LIST,
          data: res.data,
        });
      });
  }
};

export const updateTicket = (recordId, data, id, dispatch) => {
  if (recordId) {
    axios
      .patch(`${API_URL}/ticket-system/${recordId}/updateticket`, data)
      .then((res) => {
        if (res.data.success) {
          getOverviewList(id,dispatch,1);
          toastr.success("Success", res.data.message);
        } else {
          toastr.error("Error", res.data.message);
        }
      });
  }
};
