import React from "react";
import { Route, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion/dist/framer-motion.dev";
import RemediationOverview from "../SecurityRisk/RemediationOverview";
import RemediationTask from "../SecurityRisk/RemediationTask";
import RemediationTicket from "../SecurityRisk/RemediationTicket";
import RemediationTrusted from "../SecurityRisk/RemediationTrusted";
import RemediationActivityLog from "../SecurityRisk/RemediationActivityLog";


const RemediationRoute = ({ match }) => {
  return (
    <AnimatePresence>
      <Switch>
        <Route path={`${match.path}/overview`} component={RemediationOverview} />
        <Route path={`${match.path}/task`} component={RemediationTask} />
        <Route path={`${match.path}/tickets`} component={RemediationTicket} />
        <Route path={`${match.path}/trusted-user`} component={RemediationTrusted} />
        <Route path={`${match.path}/activity-log`} component={RemediationActivityLog} />
      </Switch>
    </AnimatePresence>
  );
};

export default RemediationRoute;
