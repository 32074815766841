import axios from "axios";
import { API_URL } from "../utils/constant";
import {
  INVENTORY_FETCHED,
  SINGLE_INVENTORY_FETCHED,
  FETCHING_SINGLE_INVENTORY,
  INVENTORY_DETAILS_FETCHED,
  ALL_INVENTORY_FETCHED,
  SINGLE_INVENTORY_DATA_FETCHED,
  INVENTORY_DETAILS_FETCHEDV1,
  INVENTORY_FETCHED_GRAPH,
  INVENTORY_FETCHED_DISCOVERY,
  ALL_PORT,
  ALL_CLOUD,
  ALL_COUNTRY,
} from "./index";
import { toastr } from "react-redux-toastr";

export const fetchInventory = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios
    .get(`${API_URL}/digital-inventory`, {
      params: {
        customerId: props,
      },
    })
    .then((response) => {
      //console.log(response.data);
      dispatch({
        type: INVENTORY_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: INVENTORY_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchIpInventory = (props, dispatch, page ,category,status) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if(props){
    dispatch({
    type: INVENTORY_FETCHED,
    payload: null,
  });
  axios
    .get(`${API_URL}/black-listed-ips/${props}/delta?page=${page}&category=${category}&status=${status}`, {
      params: {
        customerId: props,
      },
    })
    .then((response) => {
      dispatch({
        type: INVENTORY_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: INVENTORY_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
  }
};

export const fetchIpInventoryAction = (props, dispatch ,page,category,data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: INVENTORY_FETCHED,
      payload: null,
    });
    axios
      .post(`${API_URL}/black-listed-ips/${props}/action?page=${page}&category=${category}`,data)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: INVENTORY_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchIpInventoryGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: INVENTORY_FETCHED_GRAPH,
      data: null,
    });
    axios
      .get(`${API_URL}/black-listed-ips/${props}/graph`)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED_GRAPH,
          data: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: INVENTORY_FETCHED_GRAPH,
          data: { error: true, userMessage: message },
        });
      });
  }
};

export const createHOst = (props, dispatch) => {
  //console.log('createHOst')
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/createHost`, { ...props })
    .then((response) => {
      if(response.data.error){
        toastr.error("Error", response.data.userMessage);
        return false;
      }else{
        toastr.success("Success", response.data.userMessage);
      }
      dispatch({
        type: INVENTORY_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: INVENTORY_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
}

export const fetchInventoryById = (ip, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_SINGLE_INVENTORY,
    singleInventory: null
  });

  axios
    .get(`${API_URL}/digital-inventory/${ip}`)
    .then((response) => {
      dispatch({
        type: SINGLE_INVENTORY_FETCHED,
        singleInventory: response.data.result,
      });
    })
    .catch((err) => {
      dispatch({
        type: SINGLE_INVENTORY_FETCHED,
        singleInventory: err.message,
      });
    });
};

export const fetchSingleInventoryById = (ip, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: SINGLE_INVENTORY_DATA_FETCHED,
    singleInventory: null
  });

  axios
    .get(`${API_URL}/digital-inventory-details/${ip}`)
    .then((response) => {
      dispatch({
        type: SINGLE_INVENTORY_DATA_FETCHED,
        singleInventory: response.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SINGLE_INVENTORY_DATA_FETCHED,
        singleInventory: err.message,
      });
    });
};

export const fetchInventoryDetails = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props !== undefined) {
    axios
      .get(`${API_URL}/inventory-vulns/${props}`)
      .then((res) => {
        toastr.success("Success", res.data.userMessage);
        dispatch({
          type: INVENTORY_DETAILS_FETCHED,
          data: res.data,
        });
      })
      .catch((err) => {
        // toastr.error("Error", err.message);
      });
  }
};

export const fetchInventoryDetailsV1 = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props !== undefined) {
    axios
      .get(`${API_URL}/inventory-vulnsV1/${props}`)
      .then((res) => {
        // toastr.success("Success", res.data.userMessage);
        dispatch({
          type: INVENTORY_DETAILS_FETCHEDV1,
          data: res.data.data,
        });
      })
      .catch((err) => {
        // toastr.error("Error", err.message);
      });
  }
};

export const fetchAllInventory = (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios
    .get(`${API_URL}/getall/digital-inventory`, {
      // params: {
      //   customerId: props,
      // },
    })
    .then((response) => {
      //console.log(response.data);
      dispatch({
        type: ALL_INVENTORY_FETCHED,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: ALL_INVENTORY_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const deleteIp = (id,organisationId, dispatch) => {

   // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios
    .delete(`${API_URL}/deleteIp/${id}/${organisationId}`)
    .then((response) => {
      if(response.data.error){
        toastr.error("Error", response.data.userMessage);
        return false;
      }else{
        toastr.success("Success", response.data.userMessage);
      }
      dispatch({
        type: INVENTORY_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: INVENTORY_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
}

export const uploadIp = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/bulkUploadIP`, { organisationId: props.organisationId, ip: props.ip })
    .then((response) => {
      if(response.data.error){
        toastr.error("Error", response.data.userMessage);
        return false;
      }else{
        toastr.success("Success", response.data.userMessage);
      }
      dispatch({
        type: INVENTORY_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: INVENTORY_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
}

export const fetchIpDiscovery = (props, dispatch ,page,category,port,country,cloudprovider,ipq,subdomain) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: INVENTORY_FETCHED_DISCOVERY,
      payload: null,
    });
    axios
      .get(`${API_URL}/digital-inventory/${props}/delta?page=${page}&category=${category}&port=${port}&country=${country}&cloudprovider=${cloudprovider}&ipq=${ipq}&subdomains=${subdomain}`)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED_DISCOVERY,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: INVENTORY_FETCHED_DISCOVERY,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const allPort = (props, dispatch,category) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: ALL_PORT,
      payload: null,
    });
    axios
      .get(`${API_URL}/digital-inventory/${props}/all-ports?category=${category}`)
      .then((response) => {
        dispatch({
          type: ALL_PORT,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: ALL_PORT,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const allCountry = (props, dispatch,category) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: ALL_COUNTRY,
      payload: null,
    });
    axios
      .get(`${API_URL}/digital-inventory/${props}/all-countries?category=${category}`)
      .then((response) => {
        dispatch({
          type: ALL_COUNTRY,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: ALL_COUNTRY,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const allCloud = (props, dispatch ,category) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: ALL_CLOUD,
      payload: null,
    });
    axios
      .get(`${API_URL}/digital-inventory/${props}/all-cloudproviders?category=${category}`)
      .then((response) => {
        dispatch({
          type: ALL_CLOUD,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: ALL_CLOUD,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const assetsMonitorInternalApi = (props, dispatch, key, port,country,cloudprovider,ipq,subdomain) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: INVENTORY_FETCHED_DISCOVERY,
      payload: null,
    });
    axios
      .get(`${API_URL}/organisations/${props}/ipdetails/groupby/${key}?port=${port}&country=${country}&cloudprovider=${cloudprovider}&ipq=${ipq}&subdomains=${subdomain}`)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED_DISCOVERY,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: INVENTORY_FETCHED_DISCOVERY,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchIpDiscoveryAction = (props, dispatch ,page, category , data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    dispatch({
      type: INVENTORY_FETCHED_DISCOVERY,
      payload: null,
    });
    axios
      .post(`${API_URL}/digital-inventory/${props}/action?page=${page}&category=${category}`,data)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED_DISCOVERY,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: INVENTORY_FETCHED_DISCOVERY,
          payload: { error: true, userMessage: message },
        });
      });
  }
};


export const brandMention = (props, dispatch, page, category, search, limit) => {

  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    axios
      .get(`${API_URL}/brand-mentions/${props}/delta?page=${page}&limit=${limit}&category=${category}&subDomain=${search}`)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: INVENTORY_FETCHED,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const brandMentionAction = (props, dispatch, page, category, search, data) => {

  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  if (props) {
    axios
      .post(`${API_URL}/brand-mentions/${props}/action?page=${page}&category=${category}&subDomain=${search}`, data)
      .then((response) => {
        dispatch({
          type: INVENTORY_FETCHED,
          payload: response.data,
        });
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
        } else {
          toastr.success("Error", response.data.userMessage);
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
      });
  }
};

export const brandMentionUpdate = (props, dispatch, data, clear, category, search) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  if (props) {
    axios
      .patch(`${API_URL}/brand-mentions/${props}/update`, data)
      .then((response) => {
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
          brandMention(props, dispatch, 1, category, search);
          clear();
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
  }
};

export const brandMentionDelete = (props, id, dispatch, category, search) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  if (props) {
    axios
      .delete(`${API_URL}/brand-mentions/${props}/${id}/delete`)
      .then((response) => {
        if (response.data.status == 200) {
          toastr.success("Success", response.data.userMessage);
          brandMention(props, dispatch, 1, category, search);
        } else {
          toastr.error("Error", response.data.userMessage);
        }
      })
  }
};
