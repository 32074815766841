import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../resuableComponent/loader";
import { useLocation } from "react-router-dom";
import { getOverviewList, updateTicket } from "../../actions/remediationMode";
import moment from "moment";
import imgselect from "../../assets/images/select_box.png";
import { Pagination } from "react-bootstrap";
import DatePicker from 'react-datepicker';



const RemediationTask = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [category, setCategory] = useState("all");
  const auth = useSelector((state) => state?.auth?.user);
  const location = useLocation();
  const id = useSelector(state => { return state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id });
  const list = useSelector(state => { return state?.remediationMode?.list?.data });
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [containerClass, setContainerClass] = useState("data-container");
  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);


  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  useEffect(() => {
    if (list) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [list?.tickets]);

  useEffect(() => {
    getOverviewList(id, dispatch, page)
  }, [id, page])


   const updateTicketFunction = (_id, data) => {
      updateTicket(_id, data, id, dispatch)
    }

  return (
    <div>{list ?
      <div className={containerClass}>
        <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
          <div className='col-sm-2'></div>
          <div className='col-sm-9' style={{ padding: '0' }}>
            <div class="row_boxes row_boxes_table">
            <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                      }}
                    >
                      Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                      }}
                    >
                      In Process
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                      }}
                    >
                      Complete
                    </a>
                  </li>
                </ul>
                <div class="table_box">
                <table className="table">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                // multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                      <th>Date Added</th>
                      <th>Task Id</th>
                      <th>Type</th>
                      <th>Details</th>
                      <th>Notes</th>
                      <th>Rating</th>
                      <th>Priority</th>
                      <th>Task</th>
                      <th>Assigned</th>
                      <th>Status</th>
                      <th>Due Date</th>
                      <th>Last Updated</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.tickets?.map((r) => {
                      return <>
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                // multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </td>
                          <td>
                            {r?.createdAt
                              ? moment(r?.createdAt).format(
                                "DD-MM-YYYY"
                              ) : "NA"}
                          </td>
                          <td>
                            {r?.taskId || "N/A"}
                          </td>
                          <td>
                            {r?.actionType || "N/A"}
                          </td>
                          <td>
                            {r?.details || "N/A"}
                          </td>
                          <td>
                            {r?.note || "N/A"}
                          </td>
                          <td>
                            <div className="select-box">
                              <select className="select_box_mytask"
                                value={r?.rating}
                                onChange={(e) => {
                                  let data = {
                                    "rating": e.target.value,
                                    organisationId: id
                                  };
                                  updateTicketFunction(r?.recordId, data);
                                }}
                              >
                                <option value="">Please select</option>
                                <option value="High">High</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                              </select>
                              <div className="select_box_img">
                                <img src={imgselect} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="select-box">
                              <select className="select_box_mytask"
                                value={r?.priority}
                                onChange={(e) => {
                                  let data = {
                                    "priority": e.target.value,
                                    organisationId: id
                                  };
                                  updateTicketFunction(r?.recordId, data);
                                }}
                              >
                                <option value="">Please select</option>
                                <option value="High">High</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                              </select>
                              <div className="select_box_img">
                                <img src={imgselect} />
                              </div>
                            </div>
                          </td>
                          <td>
                            {/* {r?.task || "N/A"} */}
                            <div className="select-box">
                              <select className="select_box_mytask"
                                value={r?.priority}
                              // onChange={(e) => {
                              //   if (e.target.value != '' || e.target.value != undefined) {
                              //     let data = {
                              //       "rating": e.target.value,
                              //       "organisationId":id
                              //     }
                              //   }
                              // }
                              // }
                              >
                                <option value="">Please select</option>
                                <option value="High">Ticket</option>
                                <option value="Low">External</option>
                                <option value="Medium">Internal</option>
                              </select>
                              <div className="select_box_img">
                                <img src={imgselect} />
                              </div>
                            </div>
                          </td>
                          <td>
                            {/* {r?.moduleName || "N/A"} */}
                            <div className="select-box">
                              <select className="select_box_mytask"
                                value={r?.priority}
                              // onChange={(e) => {
                              //   if (e.target.value != '' || e.target.value != undefined) {
                              //     let data = {
                              //       "rating": e.target.value,
                              //       "organisationId":id
                              //     }
                              //   }
                              // }
                              // }
                              >
                                <option value="">Please select</option>
                                <option value="High">Ticket</option>
                                <option value="Low">External</option>
                                <option value="Medium">Internal</option>
                              </select>
                              <div className="select_box_img">
                                <img src={imgselect} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="select-box">
                              <select className="select_box_mytask"
                                value={r?.status}
                                onChange={(e) => {
                                  let data = {
                                    "status": e.target.value,
                                    organisationId: id
                                  };
                                  updateTicketFunction(r?.recordId, data);
                                }}
                              >
                                <option value="">Please select</option>
                                <option value="Open">Open</option>
                                <option value="InProgress">In Progress</option>
                                <option value="Close">Close</option>
                              </select>
                              <div className="select_box_img">
                                <img src={imgselect} />
                              </div>
                            </div>
                          </td>
                          <td>
                            {/* {r?.dueDate
                              ? moment(r?.dueDate).format(
                                "DD-MM-YYYY"
                              ) : "NA"} */}
                            {/* <input className="mdb-datatable-filter flex-row" type="date" /> */}
                            <DatePicker
                              selected={r?.dueDate == null ? "" : new Date(r?.dueDate)}
                              onChange={(date) => {
                                let data = {
                                  "dueDate": date,
                                  organisationId: id
                                };
                                updateTicketFunction(r?.recordId, data);
                              }}
                              className="mdb-datatable-filter flex-row datePickerCustomCss"
                              placeholderText="Select a date"
                              dateFormat="yyyy-MM-dd"/>
                            {/* <select className="select_box_mytask"
                                value={r?.status}
                                onChange={(e) => {
                                  let data = {
                                    "status": e.target.value,
                                    organisationId: id
                                  };
                                  updateTicketFunction(r?.recordId, data);
                                }}
                              >
                                <option value="">Please select</option>
                                <option value="Open">Open</option>
                                <option value="InProgress">In Progress</option>
                                <option value="Close">Close</option>
                              </select> */}
                          </td>
                          <td>
                            {r?.updatedAt
                              ? moment(r?.updatedAt).format(
                                "DD-MM-YYYY"
                              ) : "NA"}
                          </td>
                          <td>
                            <span className="openTask">Open Task</span>
                          </td>
                        </tr>
                      </>
                    })}
                  </tbody>
                </table>
              </div>

            </div>

            <div className="dataTables_paginate">
              {totalItems > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={totalItems}
                  pageSize={10}
                  onPageChange={(page) =>
                    setPage(page
                    )}
                />
              )}
            </div>
          </div>
        </div>
      </div> :
      <Loader />}
    </div>
  );
}

export default RemediationTask;