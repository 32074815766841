import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { domainSquating, domainSquatingAction, domainSquatingGraph, takeDown } from "../../actions/look-alike";
import moment from "moment";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import { Tabs, Tab } from "react-bootstrap";
import { API_URL, IMAGE_URL } from "../../utils/constant";
import TinyMCE from "react-tinymce";
import { toastr } from "react-redux-toastr";
import AccessPage from "../Shared/accessPage";
import SideNav from "../resuableComponent/sidenav";
import Header from "../resuableComponent/header";
import Loader from "../resuableComponent/loader";
import { motion } from "framer-motion/dist/framer-motion.dev";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";
import { useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import $ from "jquery";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";



const Domains = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const location = useLocation();
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );

  let domain = client?.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (domain?.charAt(domain.length - 1) == "/") {
    domain = [domain.substr(0, domain.length - 1)];
    // domain.unshift("all");
  } else {
    domain = [domain];
    // domain.unshift("all");
  }
  if (client?.domains && client.domains.length > 0) {
    domain = client.domains;
    // domain.unshift("all");
  }

  const dispatch = useDispatch();

  const [subject, setSubject] = useState("");
  const [stolenLocation, setStolenLocation] = useState("");
  const [contenSource, setContenSource] = useState("");
  const [infringingIp, setInfringingIp] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [rowId, setRowId] = useState("");
  const [domainFilter, setDomainFilter] = useState("all");

  const dnsTableRef = useRef(null);
  const ownerTableRef = useRef(null);

  const [filterData, setfilterData] = useState("all");
  const [isModal, setModal] = useState("");
  const [isModalDns, setModalDns] = useState("");
  const [isTakeDownModal, setTakeDownModal] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedRow, setSelectedId] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [PageSize, setPerPage] = useState(10);
  const [isModalTable, setModalTable] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const auth = useSelector((state) => state?.auth?.user);
  const [subdomain, setSubdomain] = useState("all");
  const [checkPath, setCheckPath] = useState('');
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );
  let domains = useSelector((state) => state?.lookAlike?.domainSquating?.data);
  const subDomainList = useSelector(
    (state) => state?.lookAlike?.subDomainList?.data?.domains
  );
  let domainstotal = useSelector(
    (state) => state?.lookAlike?.domainSquating?.data?.data
  );
  const scanCompleted = useSelector((state) => state?.lookAlike?.scanCompleted);

  const scanDetails = useSelector(
    (state) => state?.lookAlike?.looklikegraph?.data
  );
  const modeValue = new URLSearchParams(location?.search).get('mode');
  const [addedRemediations, setAddedRemediations] = useState([]);
  const [category, setCategory] = useState("all");

  const [containerClass, setContainerClass] = useState("data-container");
  const cartDispatch = useDispatch();
  let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

  const handleEditorChange = (even, editor) => {
    const data = editor.getData();
    setEditorContent(data); // Update the state with the new content
  };

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then(() => {
              domainSquating(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName": 'delta-domain_squattings',
      "organisationId": id,
      "recordId": row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then(() => {
              domainSquating(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  useEffect(() => {
    setCheckPath(location.search)
  }, [location.search])

  useEffect(() => {
    if (domains) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [domains]);

  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails[details]?.length);
  }

  useEffect(() => {
    domainSquating(id, dispatch, page, category, subdomain, filterData);
  }, [id, dispatch, page, category, subdomain, filterData]);

  useEffect(() => {
    domainSquatingGraph(id, dispatch)
  }, [id, dispatch])

  useEffect(() => {
    setTotalItems(domains?.totalLookalikeDomains);
  }, [domains?.totalLookalikeDomains]);

  if (domainFilter !== "all") {
    // domains = domains?.filter((val) => val?.lookalike.indexOf(domainFilter?.split(".")[0]) != -1)
    domains = domains?.filter(
      (val) => val?.original.indexOf(domainFilter) != -1
    );
  }

  let mapData = domains?.data;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const takeDownValue = (res) => {
    let ip;
    res?.dnsDetails?.map((row) => {
      return Object.keys(row || {}).map((key) => {
        let record = row[key];
        if (record && record.length == 0) return false;
        if (key !== "dns_a") return false;
        record?.map((val) => {
          ip = val;
        });
      });
    });
    let originalWebsite = client.website;
    let domainName = res.lookalike;
    setStolenLocation(originalWebsite);
    setContenSource(domainName);
    setInfringingIp(ip);
    setRowId(res._id);
    setTakeDownModal(true);
  };

  const submitTakeDownValue = () => {
    if (subject == "") {
      toastr.error("Error", "Subject cannot be empty.");
      return false;
    }
    if (stolenLocation == "") {
      toastr.error("Error", "Stolen location cannot be empty.");
      return false;
    }
    if (contenSource == "") {
      toastr.error("Error", "Content source cannot be empty.");
      return false;
    }
    if (infringingIp == "") {
      toastr.error("Error", "Infringin IP cannot be empty.");
      return false;
    }
    if (editorContent == "") {
      toastr.error("Error", "Editor content cannot be empty.");
      return false;
    }

    setTakeDownModal(false);
    takeDown(
      {
        subject,
        stolenLocation,
        contenSource,
        infringingIp,
        editorContent,
        rowId,
        id,
      },
      dispatch
    );
  };

  const dnsKeyName = (val) => {
    if (val == "dns_a") return "A Record";
    else if (val == "dns_ns") return "NS Record";
    else if (val == "dns_mx") return "MX Record";
    else if (val == "dns_aaa") return "AAA Record";
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };


  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        lookalikeDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      domainSquatingAction(
        id,
        dispatch,
        data,
        page,
        category
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: 'resetAction',
      lookalikeDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    domainSquatingAction(
      id,
      dispatch,
      data,
      page,
      category
    );
    setSelectedIds([]);
  }

  const submit = () => {
    const data = {
      action: action,
      lookalikeDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    domainSquatingAction(
      id,
      dispatch,
      data,
      page,
      category
    );
    setActionNote("");
    setModalTable("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModalTable("Form Wizard modal");
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/organisations/${id}/domainsquatting/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Look-alike domains.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1.5 }}
    >
      <div>
        {/* <Header type="brand" />
      <SideNav type="brand" /> */}

        {domains ? (
          <div className={containerClass}>
            <div
              className="row clearfix no_margin"
              style={{ marginTop: "20px" }}
            >
              <div className="col-sm-2"></div>
              <div className="col-sm-9">
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("all");
                  setPage(1);
                  setSubdomain("all");
                  setfilterData("all");
                  setSelectedIds([]);
                }}
              >
                Current
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("risk-accepted");
                  setPage(1);
                  setSubdomain("all");
                  setfilterData("all");
                  setSelectedIds([]);
                }}
              >
                Risk Accepted
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("false-positive");
                  setPage(1);
                  setSubdomain("all");
                  setfilterData("all");
                  setSelectedIds([]);
                }}
              >
                False Positive
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                  }`}
                onClick={() => {
                  setCategory("remidiated");
                  setPage(1);
                  setSubdomain("all");
                  setfilterData("all");
                  setSelectedIds([]);
                }}
              >
                Remediated
              </a>
            </li>
          </ul>
                <div className="row_boxes">
                  <div className="gradinet-effect w-100">
                    {scanDetails && (
                      <BasicLineColumn
                        dates={scanDetails?.dates}
                        ipArray={scanDetails?.scanDetails}
                        color={["#6574cd"]}
                        yAxis="Similar Domains found"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row clearfix no_margin"
              style={{ marginTop: "20px" }}
            >
              <div className="col-sm-2"></div>
              <div className="col-sm-9" style={{ padding: "0" }}>
                <div className="row mb-2">
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="registered-in-last-30-days">
                        Registered in 30 days
                      </option>
                      <option value="registered-in-last-60-days">
                        Registered in 60 days
                      </option>
                    </select>
                  </div>

                  {modeValue == "report-mode" && <>
                  <div className="col-md-4  text-right">
              {client?.subscriptionType != "trial" && currentTableData?.length !== 0 && (
                <span>
                  <a
                    onClick={() => downloadFile()}
                    style={{
                      float: "right",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o"
                      style={{ color: "black" }}
                    ></i>
                  </a>
                </span>
              )}
            </div>
            </>}
                </div>
                <div class="row_boxes row_boxes_table">
                  <div className="table_box">
                    <table className="table">
                      <thead>
                        <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                      category === "risk-accepted" ||
                      category === "false-positive") && (
                        <th>
                          <input
                            type="checkbox"
                            checked={selectedIds == 0 ? false : multiCheckTrue}
                            onClick={() => {
                              multicheck(currentTableData);
                            }}
                            className="checkUncheck"
                          />
                        </th>
                      )}
                          <th>#</th>
                          <th>Original Domain</th>
                          <th>Similar Domain</th>
                          <th>Type</th>
                          <th>Image</th>
                          <th>Domain Registered on</th>
                          <th>Domain Expiry on</th>
                          <th>IP Address</th>
                          <th>MX Record</th>
                          <th>Registrar</th>
                          <th>Actions</th>
                          <th>Last updated on</th>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                      category === "risk-accepted" ||
                      category === "false-positive") && (
                        <th style={{ width: "80" }}>Action</th>
                      )}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTableData?.map((res, index) => {
                          let mxRecord = false;
                          {
                            res?.dnsDetails?.map((row) => {
                              let flag = false;
                              Object.keys(row || {}).map((key) => {
                                let record = row[key];
                                if (record && record.length == 0) return false;
                                if ((key == "dns_mx") & (record.length > 0))
                                  mxRecord = true;
                              });
                            });
                          }
                          const pageCount = (page - 1) * 10 + 1;
                          return (
                            <tr key={index}>
                                {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                              <td>
                                <input
                                  onClick={() => singleClick(res?._id)}
                                  checked={selectedIds?.includes(res?._id)}
                                  type="checkbox"
                                  className="checkUncheck"
                                />
                              </td>
                            )}
                              <td>
                                <span>{pageCount + index}</span>
                              </td>
                              <td>
                                {" "}
                                <span
                                  style={{
                                    color: "#ffffff",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedId(res);
                                    setModal(true);
                                  }}
                                >
                                  {res?.original}
                                </span>
                                {/* <a href={`https://${res?.lookalike}`} target="_blank">
                              {res?.lookalike}
                            </a> */}
                              </td>

                              <td>
                                {" "}
                                <span
                                  style={{
                                    color: "#ffffff",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedId(res);
                                    setModal(true);
                                  }}
                                >
                                  {res?.lookalike}
                                </span>
                                {/* <a href={`https://${res?.lookalike}`} target="_blank">
                              {res?.lookalike}
                            </a> */}
                              </td>
                              <td>{res?.fuzzer}</td>

                              <td>
                                {res?.image ? (
                                  <img
                                    onClick={(e) => {
                                      setModalDns(true);
                                      setSelectedImage(res?.image);
                                    }}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      cursor: "pointer",
                                    }}
                                    src={`${IMAGE_URL}/images/${res?.image}`}
                                  />
                                ) : (
                                  "NA"
                                )}
                              </td>
                              <td>
                                {" "}
                                {res?.creationDate
                                  ? moment(res?.creationDate).format(
                                    "DD/MM/YYYY hh:mm a"
                                  )
                                  : "NA"}
                              </td>
                              <td>
                                {" "}
                                {res?.expirationDate
                                  ? moment(res?.expirationDate).format(
                                    "DD/MM/YYYY hh:mm a"
                                  )
                                  : "NA"}
                              </td>
                              <td>
                                {res?.dnsDetails?.map((row) => {
                                  return Object.keys(row || {}).map((key) => {
                                    let record = row[key];
                                    if (record && record.length == 0)
                                      return false;
                                    if (key !== "dns_a") return false;
                                    return (
                                      <>
                                        {record?.map((val) => {
                                          return (
                                            <>
                                              <span>{val}</span>
                                              <br />
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  });
                                })}
                              </td>
                              <td>
                                {mxRecord ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: "&#10004",
                                    }}
                                  ></span>
                                ) : (
                                  "X"
                                )}
                              </td>
                              <td>{res?.registrar || "NA"}</td>
                              <td>
                                {res?.isDomainTakeDownApplied ? (
                                  "Request Submitted"
                                ) : (
                                  <button
                                    className="btn btn-danger btn-sm mr-1"
                                    // to={''}
                                    onClick={() => takeDownValue(res)}
                                  >
                                    Request
                                    <br />
                                    Takedown
                                  </button>
                                )}
                              </td>
                              <td>
                                {res?.updatedAt
                                  ? moment(res?.updatedAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                  : res?.createdAt
                                    ? moment(res?.createdAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                    : "NA"}
                              </td>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(res?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId(res._id);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId(res._id);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                            category === "false-positive") && (
                              <td>
                                <button
                                  className="btn btn-primary btn-sm mr-1 Unsnooze"
                                  onClick={() => Unsnoozed(res?._id)}
                                >
                                  Unsnooze
                                </button>
                              </td>
                            )}
                              {clientType == "Admin" ?
                                <td>
                                  {res?.isTicketChecked == false ? (
                                    <button
                                      onClick={addToCart({
                                        ...res,
                                        remediationType: "Sub Domain",
                                      })}
                                    >
                                      <span className="fa fa-plus action_icon"></span>
                                    </button>
                                  ) : (
                                    <button onClick={removeToCart(res?._id)}>
                                      <span className="fa fa-minus action_icon"></span>
                                    </button>
                                  )}
                                </td>
                                : null}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`modal fade Form-Wizard-modal ${isModalTable === "Form Wizard modal" ? "d-block show" : ""
                }`}
            >
              <div className="modal-dialog modal-lg" id="clientModal">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {action == "markAsFalsePositive"
                        ? "Mark As False Positive"
                        : "Risk Accepted"}
                    </h5>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setModalTable("");
                        setActionNote("");
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body pricing_page modal_mob">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="card">
                        <div className="row clearfix">
                          <div className="col-md-12">
                            <label className="col-lg-6 col-md-12">Snooze for</label>
                            <div className="form-group ol-lg-12 col-md-12">
                              <select
                                className="custom-select custom-select-sm form-control form-control-sm"
                                onChange={(e) => setForValue(e.target.value)}
                              >
                                <option value="forever">Forever</option>
                                <option value="3 months">3 Months</option>
                                <option value="6 months">6 Months</option>
                                <option value="12 months">12 Months</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <label className="col-lg-12 col-md-12">Detail</label>
                            <div className="form-group col-lg-12 col-md-12">
                              <textarea
                                onChange={(e) => setActionNote(e.target.value)}
                                value={actionNote}
                                className="form-control"
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-lg-12 col-md-12"
                            style={{ marginRight: "28px" }}
                          >
                            <button
                              type="button"
                              style={{ color: "black", fontWeight: "bold" }}
                              onClick={submit}
                              className="btn btn-round btn-primary mr-1 pull-right"
                            >
                              Submit
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              onClick={() => {
                                setModalTable("");
                                setActionNote("");
                              }}
                              className="btn btn-round btn-default pull-right"
                              style={{ marginRight: "10px" }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`modal fade Form-Wizard-modal ${isTakeDownModal ? "d-block show" : ""
                }`}
            >
              <div className="modal-dialog modal-lg" id="clientModal">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Website Takedown Request
                    </h5>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setTakeDownModal(false)}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card card_ck">
                          <div className="body">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subject}
                                  onChange={(e) => setSubject(e.target.value)}
                                  placeholder="Subject"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={stolenLocation}
                                  onChange={(e) => setStolenLocation(e.target.value)}
                                  placeholder="Stolen Location"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={contenSource}
                                  onChange={(e) => setContenSource(e.target.value)}
                                  placeholder="Content Source"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={infringingIp}
                                  onChange={(e) => setInfringingIp(e.target.value)}
                                  placeholder="Infringing IP"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <label>Description</label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={editorContent}
                                onChange={handleEditorChange}
                                config={{
                                  styles: [
                                    `
              .ck-editor__editable {
                min-height: 200px; /* Set your desired minimum height */
              }
              p, h1, h2, h3 {
                color: black; /* Set text color to black */
              }
            `,
                                  ],
                                }}
                              />
                              {/* <TinyMCE
                          content="<p></p>"
                          config={{
                            plugins: "autolink link image lists print preview",
                            toolbar:
                              "undo redo | bold italic | alignleft aligncenter alignright",
                          }}
                          onChange={handleEditorChange}
                        /> */}
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <button
                                className="btn btn-danger btn-sm mr-1"
                                // to={''}
                                style={{ marginTop: "10px" }}
                                onClick={() => submitTakeDownValue()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default Domains;
