import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { toggleSearchBar } from "../../actions/settingsAction";
import trainningCommonImg from "../../assets/images/Training_CommonMenu.svg"
import aboutImg from "../../assets/images/About_CommonMenu.svg"
import companyImg from "../../assets/images/ABout_Comp_CommonMenu.svg"
import contactUs from "../../assets/images/Conatct_Us_CommonMenu.svg"
import legalInfo from "../../assets/images/Legal_CommonMenu.svg"
import privacyImg from "../../assets/images/Privacy_CommonMenu.svg"
import partnerImg from "../../assets/images/Partners_CommonMenu.svg"
import licencesImg from "../../assets/images/Licenses_CommonMenu.svg"
import userSetting from "../../assets/images/user_settings.svg"
import userGuide from "../../assets/images/guidance.svg"
import userGuideReport from "../../assets/images/UserGuide_CommonMenu.svg"
import { MODE_SETTING } from "../../actions";



const Header = (
  {
    toggleSearchBar
  }) => {
  const location = useLocation();
  const history = useHistory();
  const [isModal, setModal] = useState(false);
  const dispatch = useDispatch();
  const orgName = useSelector(
    (state) =>
      state?.summary?.localClient?.orgName || state?.summary?.clients?.data?.[0]?.orgName
  );
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const mode = useSelector(
    (state) =>
      state?.summary?.modes?.data || "assessment-mode"
  );
  const [currentRoute, setCurrentRoute] = useState("dashboard");

  const handleModeClick = (v) => {
    dispatch({
      type: MODE_SETTING,
      data: v,
    });
    if (v === "remediation-mode") {
      history.push("/remediation-mode/overview");
    }
    if (v === "assessment-mode") {
      history.push("/");
    }
  };

  let selectedRoute = "dashboard";


  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/summary") {
      selectedRoute = "dashboard";
    } else if (
      pathname.indexOf("security") !== -1 ||
      pathname === "/domains" ||
      pathname === "/known-vulnerabilities" ||
      pathname === "/ssl-certificates" ||
      pathname === "/web-technology"
    ) {
      selectedRoute = "security";
    } else if (pathname.indexOf("brand") !== -1 || pathname === "/mobile-apps" || pathname === "/look-alike" || pathname === "/social-media") {
      selectedRoute = "brand";
    } else if (
      pathname.indexOf("breach") !== -1 ||
      pathname === "/stolen-credentials" ||
      pathname === "/malware-credentials" ||
      pathname === "/source-code" ||
      pathname === "/leaked-sessions" ||
      pathname === "/cloud-bucket" ||
      pathname === "/ip-blacklist"
    ) {
      selectedRoute = "breach";
    }
    else if (
      pathname == "/assets" || pathname == "/assets/"
    ) {
      selectedRoute = "assets";
    }
    else if (
      pathname == "/risk"
    ) {
      selectedRoute = "risk";
    }
    else if (
      pathname == "/profile"
    ) {
      selectedRoute = "profile";
    }
    setCurrentRoute(selectedRoute);
  }, [location.pathname]);


  return (
    <>
      {location.pathname.replace('/', '') !== 'services' ?
        <>
          <header>
            <div class="nav_main">
              <div class="left_box_1">
                <div class="logo_img">
                  <img src="../assets/images/image-gallery/logo.svg" class="img-fluid" />
                </div>
                {mode == "settings-mode" && <>
                  <Link to="/summary" onClick={() => {
                    handleModeClick('assessment-mode');
                  }}>
                    <div class="activeheader text_logo_box">
                      <p>
                        {'<< Return'}
                        <span style={{
                          padding: ' 0px 0px 0px 14px;'
                        }}>to iOdin Panel</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src="https://liveattackmap.azurewebsites.net/images/map-elements/Map_Return_BTN.svg"
                            class="img-fluid w-100 h-100" />
                        </div>
                      </div>
                    </div>
                  </Link></>
                }
                {mode != 'settings-mode' &&
                  <><Link onClick={() => {
                    handleModeClick(mode);
                  }} to={mode == "assessment-mode" ? "/summary" : "/remediation-mode/overview"}>
                    <div class={currentRoute === "dashboard" ? "text_logo_box activeheader" : "text_logo_box"}>
                      <p>
                        Home/
                        <span>Dashboard</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={"../assets/images/image-gallery/dashboard.svg"}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                    {mode != "remediation-mode" && <>
                      <Link to="/assets/#hostsDiscovered">
                        <div class={currentRoute === "assets" ? "text_logo_box activeheader" : "text_logo_box"}>
                          <p>
                            Assets
                            <span>Monitor</span>
                          </p>
                          <div class="circle_button">
                            <div class="inner_box">
                              <img
                                src={"../assets/images/Assets_IconMain_.svg"}
                                class="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to="/security/domains">
                        <div class={currentRoute === "security" ? "text_logo_box activeheader" : "text_logo_box"}>
                          <p>
                            Security
                            <span>Healthcheck</span>
                          </p>
                          <div class="circle_button">
                            <div class="inner_box">
                              <img
                                src="../assets/images/Health_IconMain_.svg"
                                class="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </Link><Link to="/brand/mobile-apps">
                        <div class={currentRoute === "brand" ? "text_logo_box activeheader" : "text_logo_box"}>
                          <p>
                            Brand
                            <span>Security</span>
                          </p>
                          <div class="circle_button">
                            <div class="inner_box">
                              <img
                                src="../assets/images/Brand_IconMain_.svg"
                                class="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </Link><Link to="/breach/stolen-credentials">
                        <div class={currentRoute === "breach" ? "text_logo_box activeheader" : "text_logo_box"}>
                          <p>
                            Breach
                            <span>Detection</span>
                          </p>
                          <div class="circle_button">
                            <div class="inner_box">
                              <img
                                src="../assets/images/Breach_IconMain_.svg"
                                class="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </Link>
                      {
                        clientType == "Admin" && <>

                          <Link>
                            <div class={currentRoute === "risk" ? "text_logo_box activeheader" : "text_logo_box"}>
                              <p>
                                Risk
                                <span>Managment</span>
                              </p>
                              <div class="circle_button">
                                <div class="inner_box">
                                  <img
                                    src="../assets/images/Risk_Manegement_Main.svg"
                                    class="img-fluid" />
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      }</>}
                  </>
                }
              </div>
              <div class="right_box_2">
                {mode != "settings-mode" &&
                  <>
                    <div
                      onClick={() => {
                        handleModeClick('assessment-mode');
                      }}
                      class={`go_text ${mode === 'assessment-mode' ? 'active_progressBar' : ''}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        class="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          class="progress-bar red_progress_bar"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                      <div class="progress_text">
                        <img
                          src="../assets/images/image-gallery/assesment_mode.svg"
                          class="img-fluid"

                        />
                        <p>Assessment Mode.</p>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        handleModeClick('remediation-mode');
                      }}

                      class={`go_text ${mode === 'remediation-mode' ? 'active_progressBar' : ''}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        class="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          class="progress-bar green_progress_green"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                      <div class="progress_text">
                        <img
                          src="../assets/images/image-gallery/report_mode.svg"
                          class="img-fluid"

                        />
                        <p>Remediation Mode.</p>
                      </div>
                    </div>
                  </>}
                {mode == 'settings-mode' && <>
                  <div
                    style={{ cursor: 'pointer' }}
                    class={`go_text ${mode === 'settings-mode' ? 'active_progressBar' : ''}`}
                  >
                    <div
                      class="progress"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        class="progress-bar green_progress_yellow"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                    <div class="progress_text">
                      <img
                        src="../assets/images/image-gallery/settings_mode.svg"
                        class="img-fluid"

                      />
                      <p>Settings Mode.</p>
                    </div>
                  </div>
                </>
                }
              </div>
              <div className="d-flex">
                {mode != 'settings-mode' &&
                  <>
                    <div className="left_box_1 mr-5">
                      {/* {
                        clientType == "Admin" && <>

                          <Link>
                            <div class='text_logo_box'>
                              <p>
                                Other Services
                              </p>
                            </div>
                          </Link>
                        </>} */}
                      <Link
                        onClick={() => {
                          handleModeClick('service-mode');
                        }}
                        to="/services#identify"
                      >
                        <div class={"text_logo_box"}>
                          <p>
                            Valkyrie
                            <span>Special</span>
                            Services
                          </p>
                          <div class="circle_button">
                            <div class="inner_box">
                              <img
                                src="../assets/images/valkyrie_service.svg"
                                class="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </Link>
                      {
                        clientType == "Admin" && <>
                          <Link>
                            <div class={"text_logo_box"}>
                              <p>
                                Learning
                                <span>Training</span>
                                Certification
                              </p>
                              <div class="circle_button">
                                <div class="inner_box">
                                  <img
                                    src={trainningCommonImg}
                                    class="img-fluid" />
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>}
                    </div>
                    {
                      clientType == "Admin" && <>
                        <div className="left_box_1">
                          {/* <Link>
                            <div class='text_logo_box'>
                              <p>
                                General Info
                              </p>
                            </div>
                          </Link> */}
                          <Link>
                            <div class={"text_logo_box"}>
                              <p>
                                General Info
                              </p>
                              <div class="circle_button">
                                <div class="inner_box">
                                  <img
                                    src={contactUs}
                                    class="img-fluid" />
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>}
                    <div className="left_box_1">
                      {
                        clientType == "Admin" && <>
                          {/* <Link>
                            <div class='text_logo_box'>
                              <p>
                                User Services
                              </p>
                            </div>
                          </Link> */}
                          <Link>
                            <div class={"text_logo_box"}>
                              <p>
                                Userguides
                                <span>Support</span>
                              </p>
                              <div class="circle_button">
                                <div class="inner_box">
                                  <img
                                    src={userGuideReport}
                                    class="img-fluid" />
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>}
                      <Link to={`/user/${id}`}
                        onClick={() => {
                          handleModeClick('settings-mode');
                        }}>
                        <div class={currentRoute === "client" ? "text_logo_box activeheader" : "text_logo_box"}>
                          <p>
                            <span>Settings</span>
                          </p>
                          <div class="circle_button">
                            <div class="inner_box">
                              <img
                                src={userSetting}
                                class="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                }
                <div class="right_box_3">
                  <div class="sign_out">
                    <a href="javascript:void(0)" onClick={() => setModal(true)}>
                      <img src="../assets/images/image-gallery/signout.svg" class="img-fluid" />
                      <p>Sign Out</p>
                    </a>
                    <p>Signed in as: <span style={{ maxWidth: '100px' }}>{orgName}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`modal fade  ${isModal ? "d-block show" : ""}`} id="exampleModal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Log out?
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to Logout?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => setModal(false)}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-round btn-primary"
                      onClick={() => {
                        localStorage.clear();
                        window.location.replace("/login");
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </> : <>
          <header>
            <div class="nav_main nav_main_serviceHeader">
              <div class="left_box_1">
                <div className="d-flex">
                  <div class="logo_img">
                    <img src="../assets/images/image-gallery/logo.svg" class="img-fluid" />
                  </div>
                  <Link to="/summary" >
                    <div class="activeheader text_logo_box">
                      <p>
                        {'<< Return'}
                        <span style={{
                          padding: ' 0px 0px 0px 14px;'
                        }}>to iOdin Panel</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src="https://liveattackmap.azurewebsites.net/images/map-elements/Map_Return_BTN.svg"
                            class="img-fluid imginner_box h-100 w-100" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class='text_logo_box' style={{ margin: '0px 4px' }}>
                      <p>
                        Other Services
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class="text_logo_box text_logo_box_bg">
                      <p>
                        Valkyrie
                        <span>Special</span>
                        Services
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src="../assets/images/valkyrie_service.svg"
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Learning
                        <span>Training</span>
                        Certification
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={trainningCommonImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class='text_logo_box'>
                      <p>
                        General Info
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class="text_logo_box">
                      <p>
                        About
                        <span>Us</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={aboutImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Company
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={companyImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Contact
                        <span>Us</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={contactUs}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Legal
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={legalInfo}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Privacy
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={privacyImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Partners
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={partnerImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Licences/
                        <span>Certifications</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={licencesImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex">
                  <Link>
                    <div class='text_logo_box'>
                      <p>
                        User Services
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Userguides
                        <span>Support</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={userGuide}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/profile">
                    <div class={"text_logo_box"}>
                      <p>
                        User
                        <span>Settings</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={userSetting}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="right_box_3">
                <div class="sign_out">
                  <a href="javascript:void(0)" onClick={() => setModal(true)}>
                    <img src="../assets/images/image-gallery/signout.svg" class="img-fluid" />
                    <p>Sign Out</p>
                  </a>
                  <p>Signed in as: <span style={{ maxWidth: '100px' }}>{orgName}</span></p>
                </div>
              </div>
            </div>
            <div className={`modal fade  ${isModal ? "d-block show" : ""}`} id="exampleModal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Log out?
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to Logout?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => setModal(false)}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-round btn-primary"
                      onClick={() => {
                        localStorage.clear();
                        window.location.replace("/login");
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </>
      }
    </>

  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  toggleSearchBar: (e) => dispatch(toggleSearchBar(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

