import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMobileApp, fetchMobileRemidiatedFalseTrue, fetchScanGraph } from "../../actions/fakeMobileApp";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Loader from "../resuableComponent/loader";
import { motion } from "framer-motion/dist/framer-motion";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";
import BasicLineColumn from "../common/apexChart/lineChart";
import Barchart from "../common/barchart";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";


const MobileApp = () => {
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [value, setvalue] = useState("totals");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [type, settype] = useState("all");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [category, setCategory] = useState("all");
  const location = useLocation();
  const [checkPath, setCheckPath] = useState('');
  const auth = useSelector((state) => state?.auth?.user);
  const modeValue = new URLSearchParams(location?.search).get('mode');
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );

  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );

  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMobileApp(id, dispatch, page, type, category);
  }, [id, dispatch, page, type, category]);

  const state = useSelector((state) => state?.fakeMobileApp?.mobileData?.data);
  const scanDetails = useSelector(
    (state) => state?.fakeMobileApp?.fakemobapp?.data
  );
  const cartDispatch = useDispatch();
  let remediationList = useSelector(
    (state) => state?.auth?.remediationListAll?.data?.data
  );
  const [addedRemediations, setAddedRemediations] = useState([]);

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then((res) => {
              fetchMobileApp(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
        },
      },
    });
  }


  const addToCart = (row) => e => {
    let data = {
      "collectionName": 'deltamobileapps',
      "organisationId": id,
      "recordId": row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then(() => {
              fetchMobileApp(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  useEffect(() => {
    fetchScanGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setCheckPath(location.search)
  }, [location.search])

  useEffect(() => {
    setTotalItems(state?.totalApps);
  }, [state?.totalApps]);

  let mapData = state?.data;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const storeName = (val) => {
    if (val == "ios") return "iOS";
    else if (val == "android") return "Android";
    else return "Third Party";
  };

  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [state]);

  let androidApps = [],
    iosApps = [],
    thirdPartyApps = [];
  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails[details]?.length);
    let androidApp = scanDetails.scanDetails[details]?.filter(
      (val) =>
        val.source == "google" ||
        val.appStoreResource == "google" ||
        val.source == "Android"
    );
    let iosApp = scanDetails.scanDetails[details]?.filter(
      (val) =>
        (val.source || val.appStoreResource || "")?.toLowerCase() == "ios"
    );
    let thirdPartyApp = scanDetails.scanDetails[details]?.filter(
      (val) =>
        val.source == "thirdparty" ||
        val.appStoreResource == "thirdparty" ||
        val.source == "Third Party Store"
    );

    androidApps.push(androidApp?.length || 0);
    iosApps.push(iosApp?.length || 0);
    thirdPartyApps.push(thirdPartyApp?.length || 0);
  }

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        lookalikeAppIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, category);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      lookalikeAppIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, category);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      lookalikeAppIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, category);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/get-appsv2/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Rogue Mobile Application Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <motion.div
      intial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: 0.2 }}
    >
      {state ? (
        <div className={containerClass}>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {scanDetails && (
                    <Barchart
                      dates={scanDetails?.dates}
                      androidApps={androidApps}
                      iosApps={iosApps}
                      thirdPartyApps={thirdPartyApps}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
              <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("all");
                      settype("all");
                      setPage(1);
                      setSelectedIds([]);
                    }}
                  >
                    Current
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("risk-accepted");
                      settype("all");
                      setPage(1);
                      setSelectedIds([]);
                    }}
                  >
                    Risk Accepted
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("false-positive");
                      settype("all");
                      setPage(1);
                      setSelectedIds([]);
                    }}
                  >
                    False Positive
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                      }`}
                    onClick={() => {
                      setCategory("remidiated");
                      settype("all");
                      setPage(1);
                      setSelectedIds([]);
                    }}
                  >
                    Remediated
                  </a>
                </li>
              </ul>
              <div className="row mb-2">
                <div className="col-lg-4 col-md-12">
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    onChange={(e) => { settype(e.target.value); setPage(1) }}
                    value={type}
                  >
                    <option value="all">All</option>
                    <option value="android">Android</option>
                    <option value="ios">iOS</option>
                    <option value="third-party">Third Party</option>
                  </select>
                </div>
                {modeValue == "report-mode" && <>
                  <div className="col-md-6 col-sm-12 text-right hidden-xs">
                    {client?.subscriptionType != "trial" && currentTableData?.length !== 0 && (
                      <span>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          style={{
                            float: "right",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          href="/add-report"
                          className="btn btn-primary mr-1"
                        >
                          <i
                            className="fa fa-file-excel-o"
                            style={{ color: "black" }}
                          ></i>
                        </a>
                      </span>
                    )}
                  </div>
                </>}
              </div>
              <div className="row_boxes row_boxes_table">
                <div className="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>#</th>
                        <th>Store Name</th>
                        <th style={{ width: "80" }}>Application Name</th>
                        <th style={{ width: "80" }}>Application Url</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((res, index) => {
                        const pageCount = (page - 1) * 10 + 1;
                        return (
                          <tr key={index}>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(res?._id)}
                                    checked={selectedIds?.includes(res?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>
                              <span>{pageCount + index}</span>
                            </td>
                            <td>
                              {storeName(
                                res?.source || res?.appStoreResource
                              )}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              {res?.app_name}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <a
                                href={`${res?.url}`}
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                {res?.url}
                              </a>
                            </td>
                            {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                              <td>
                                <Box sx={{ width: 200 }}>
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedValue}
                                      onChange={(event) => {
                                        setAction(event.target.value);
                                        snooze(
                                          res?._id,
                                          event.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem value="pleaseSelect">
                                        Snooze Options
                                      </MenuItem>
                                      <MenuItem
                                        value="riskAccepted"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([res._id]);
                                        }}
                                      >
                                        Risk Accepted
                                      </MenuItem>
                                      <MenuItem
                                        value="markAsFalsePositive"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([res._id]);
                                        }}
                                      >
                                        False Positive
                                      </MenuItem>
                                      <MenuItem value="markAsRemidiated">
                                        Remediated
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                            )}
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(res?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            {clientType == "Admin" ?
                              <td>
                                {res?.isTicketChecked == false ? (
                                  <button
                                    onClick={addToCart({
                                      ...res,
                                      remediationType: "Mobile Application",
                                    })}
                                  >
                                    <span className="fa fa-plus action_icon"></span>
                                  </button>
                                ) : (
                                  <button onClick={removeToCart(res?._id)}>
                                    <span className="fa fa-minus action_icon"></span>
                                  </button>
                                )}
                              </td>
                              : null}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">Snooze for</label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </motion.div>
  );
};

export default MobileApp;
